import styled from "styled-components"
import { Panel } from "./ModalsShared"

const widgetModeAlwaysCenterCSSOverrides = `
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  bottom: initial !important;
  border-radius: 20px;
  width: calc(100% - 10px);
  overflow-y: hidden;

`

export const PanelPrompt = styled(Panel)`
  width: 400px;
  max-height: calc(100% - 40px);
  overflow: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .top,
  .bottom {
    padding: 30px;
  }
  .bottom {
    border-top: 1px solid #ddd;
    display: flex;
  }
  @media screen and (max-width: 550px) {
    max-height: 100%;
    width: 100%;
    top: initial;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: auto;
    transform: none;
  }
  ${({ onlyCenterModals }) => {
    if (onlyCenterModals)
      return `
       @media screen and (max-width: 550px) {
      ${widgetModeAlwaysCenterCSSOverrides}
        }
  `
  }}
`

export const PanelProduct = styled(Panel)`
  width: 1050px;
  max-height: calc(100% - 40px);
  overflow: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .top,
  .bottom {
    padding: 30px;
  }
  .bottom {
    border-top: 1px solid #ddd;
    display: flex;
  }
  @media screen and (max-width: 1030px) {
    width: calc(100% - 20px);
  }
  @media screen and (max-width: 730px) {
    width: 450px;
  }

  @media screen and (max-width: 550px) {
    max-height: 100%;
    width: 100%;
    top: initial;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    left: 0;
    /* bottom: 0; */
    overflow: auto;
    transform: none;
  }
  ${({ onlyCenterModals }) => {
    if (onlyCenterModals)
      return `
       @media screen and (max-width: 550px) {
      ${widgetModeAlwaysCenterCSSOverrides}
        }
  `
  }}
`

export const PanelBooking = styled(Panel)`
  width: 800px;
  /* overflow: auto; */
  max-height: 100%;
  /* position: absolute; */
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translate(-50%, -50%); */
  * {
    box-sizing: border-box !important;
  }
  // hide scroll
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media screen and (max-width: 800px) {
    flex: 1;
    overflow-y: hidden;
    /* width: 100%;
    max-height: 100%;
    border-radius: 0px;
    position: absolute;
    left: 0;
    bottom: 0;
    top: initial;
    overflow: auto;
    transform: none; */
  }
  ${({ onlyCenterModals }) => {
    if (onlyCenterModals)
      return `
       @media screen and (max-width: 800px) {
      ${widgetModeAlwaysCenterCSSOverrides}
        }
  `
  }}
`
