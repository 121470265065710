import React, { useState, useEffect } from "react"
import axios from "axios"
import { ButtonGrayOutline } from "../../core/Buttons"
import ReactTagInput from "@pathofdev/react-tag-input"
import {
  SubmitText,
  CheckBoxLabel,
  Label,
  ReactTagInputContainer,
  Submit,
  OptTipInput,
} from "./BookingsModalContactFormElems"
import isEmail from "validator/lib/isEmail"
import { segmentAction } from "../../hooks/useSegmentTrack"
import {
  getAllAvailablePromoCodes,
  getFormFieldsWithoutPromoCodes,
} from "./utils"

const BookingsModalContactRequest = ({
  customerInfo,
  setCustomer,
  booking: {
    price: initPrice,
    additionalFormId,
    payment_type,
    descriptionLabel,
    id,
  },
  onSubmit,
  isProcessing,
  tenantInfo,
  isGroupClass,
  isContactOnly,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState(false)
  const [additionalForm, setAdditionalForm] = useState()
  const [customFormFields, setCustomFormFields] = useState([])

  const validEmail = isEmail((customerInfo || {}).email || "")
  const showEmailError = clickedSubmit && !validEmail
  const hasErrors = !validEmail

  useEffect(() => {
    if (additionalFormId) {
      // call for list of classes
      const baseURL = process.env.GATSBY_SPARKLE_API_PATH
      const apiCode = process.env.GATSBY_SPARKLE_API_CODE
      const api = axios.create({ baseURL })
      api
        .get(
          `GeneralFormsHandler?code=${apiCode}&tenantId=${tenantInfo.tenantId}&tenantFormId=${additionalFormId}`
        )
        .then((response) => {
          if (response.data) {
            setAdditionalForm(response.data)
          }
        })
        .catch((error) => console.log({ error }))
    }
  }, [additionalFormId])

  useEffect(() => {
    if (additionalForm && id) {
      const remainingFields = getFormFieldsWithoutPromoCodes(id, additionalForm)

      setCustomFormFields(remainingFields)
    }
  }, [additionalForm, id])

  const onCustomerKeyChange =
    (key, checked = false) =>
    (e) => {
      const value = checked ? e.target.checked : e.target.value // good to capture before set state stuff (async)
      setCustomer((prev) => {
        return { ...prev, [key]: value }
      })
    }

  const onCustomerMetaKeyChange =
    (key, checked = false) =>
    (e) => {
      const value = checked ? e.target.checked : e.target.value // good to capture before set state stuff (async)

      setCustomer((prev) => {
        return { ...prev, metadata: { ...prev.metadata, [key]: value } }
      })
    }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {isGroupClass && (
        <div
          style={{
            paddingBlock: "0 1rem",
            fontSize: "calc(0.8rem + 0.5vh)",
          }}
        >
          <p
            style={{
              paddingBlock: "0 calc(0.8rem + 0.5vh)",
            }}
          >
            Unfortunately, there aren't any available spots left for this event,
            but we can't wait to connect with you!
          </p>
          <p>Sign up to be updated when new sessions are added.</p>
        </div>
      )}
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          overflowY: "scroll",
        }}
        onSubmit={(e) => {
          e.preventDefault()
          setClickedSubmit(true)
          // TODO: This should be passed in instead of individual info passed to onSubmit
          if (onSubmit && !hasErrors) {
            segmentAction.track(
              `Sparkles Manual Contact Request Submit`,
              { bookingId: id },
              { tenantInfo }
            )
            onSubmit(e)
          } else {
            segmentAction.track(
              `Sparkles Booking Submit Error`,
              { bookingId: id, value: "Invalid Email" }, // for now only invalid opt is email that can somehow pass browser form validation ie email@e
              { tenantInfo }
            )
          }
        }}
      >
        <div style={{ overflowY: "scroll" }}>
          <Label>
            <span>Name *</span>
            <input
              type="text"
              name="name"
              required
              defaultValue={customerInfo.name}
              onChange={onCustomerKeyChange("name")}
            />
          </Label>
          <Label>
            <span>Phone</span>
            <input
              type="text"
              name="phone"
              defaultValue={customerInfo.phone}
              onChange={onCustomerKeyChange("phone")}
            />
          </Label>
          <Label style={{ position: "relative" }}>
            <span>
              E-mail *{" "}
              {showEmailError ? (
                <i style={{ color: "var(--error)" }}> Invalid email</i>
              ) : (
                ""
              )}
            </span>
            <div style={{ display: "flex" }}>
              <input
                style={{ paddingRight: "9ch" }}
                className={showEmailError ? "error" : ""}
                type="email"
                name="email"
                required
                defaultValue={customerInfo.email}
                onChange={onCustomerKeyChange("email")}
              />
            </div>
          </Label>
          {customerInfo.phone && (
            <Label>
              <span>Opt-in SMS/Text Reminders</span>
              <select
                id="smstextreminders"
                defaultValue={null}
                onChange={onCustomerMetaKeyChange("enableSmsReminders")}
                required={false}
              >
                <option key={-999} value={null}>
                  -Select-
                </option>
                <option key={"yes"} value={true}>
                  Yes
                </option>
                <option key={"no"} value={false}>
                  No
                </option>
              </select>
            </Label>
          )}

          <Label>
            <span>{isGroupClass ? "Notes" : "Requested Times"}</span>
            <textarea
              rows={3}
              name="description"
              placeholder={
                isGroupClass
                  ? "Optional"
                  : "Please list some alternative times that work for you."
              }
              defaultValue={customerInfo.description}
              onChange={onCustomerKeyChange("description")}
            />
          </Label>

          {additionalForm &&
            customFormFields
              .sort((a, b) => a.order - b.order)
              .map((o, i) => {
                if (o.type === "select") {
                  return (
                    <Label key={`${o.destinationPropertyName}-i`}>
                      <span>{o.formFieldName}</span>
                      <select
                        id={o.destinationPropertyName}
                        defaultValue={null}
                        onChange={onCustomerMetaKeyChange(
                          o.destinationPropertyName
                        )}
                        required={o.isRequired || false}
                      >
                        <option key={-999} value={null}>
                          -Select-
                        </option>
                        {(o.optionValues || []).map((p) => (
                          <option key={p} value={p}>
                            {p}
                          </option>
                        ))}
                      </select>
                    </Label>
                  )
                }

                // Default - text
                return (
                  <Label key={`${o.destinationPropertyName}-i`}>
                    <span>{o.formFieldName}</span>
                    <input
                      type="text"
                      name={o.destinationPropertyName}
                      defaultValue=""
                      onChange={onCustomerMetaKeyChange(
                        o.destinationPropertyName
                      )}
                      required={o.isRequired || false}
                    />
                  </Label>
                )
              })}
        </div>
        <Submit
          className={isProcessing ? "loading" : ""}
          style={{
            marginTop: 10,
          }}
        >
          {/* Check for id just so we don't get error prior to selecting element */}
          {id && (
            <SubmitText
              payment_type={"contactOnly"}
              isProcessing={isProcessing}
              ignorePayment={true}
            />
          )}
        </Submit>
      </form>
    </div>
  )
}

export default BookingsModalContactRequest
