import React, { useEffect, useState } from "react"
import axios from "axios"
import { capitalizeFirstLetter, parseTimeForBookingsSummary } from "../utils"
import moment from "moment"
import { ButtonPrimary } from "../../core/Buttons"
import {
  SubmitText,
  Label,
  Submit,
} from "./BookingsModalContactFormElems"
import { segmentAction } from "../../hooks/useSegmentTrack"

const BookingsModalManualCalendar = ({
  tenantId,
  tenantInfo,
  style,
  setView,
  selectedBooking,
  setBookingInfo,
  setTimeZone,
  timezone,
  setIgnorePayment,
  setIsContactOnly,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState(false)
  const [event, setEvent] = useState({})
  const hasErrors = false;

  const { id: projectId } = selectedBooking || {}

  return (
    <>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flex: 1,
        }}
        onSubmit={(e) => {
          e.preventDefault()
          setClickedSubmit(true)

          const { start, end } = event
          const nylasBookingInfo = {
            resources: [{ id: null, timezone }],
            start: moment(start, "YYYY-MM-DDTh:mm:ssZZ"),
            end: moment(end, "YYYY-MM-DDTh:mm:ssZZ"),
            timezone,
            booking: {
              graph: "manual",
              id: projectId,
              resource: null,
            },
          }

          setIsContactOnly(true)
          setIgnorePayment(true)
          setBookingInfo(nylasBookingInfo)
          setTimeZone(nylasBookingInfo.timezone)
          setView("contact")
        }}
      >
        <Label>
          <span>Start</span>
          <input
            type="datetime-local"
            name="date-start"
            required
            min={new Date().toISOString().slice(0, -8)}
            onChange={(e) => setEvent({ ...event, start: e.target.value })}
          />
        </Label>
        <Label>
          <span>End</span>
          <input
            type="datetime-local"
            name="date-end"
            required
            min={new Date().toISOString().slice(0, -8)}
            onChange={(e) => setEvent({ ...event, end: e.target.value })}
          />
        </Label>
        <Submit
          style={{ marginTop: 10 }}
        >
          Send Request
        </Submit>
      </form>
    </>
  )
}

export default BookingsModalManualCalendar
