import styled from "styled-components"

// For bookings + products
const PageSectionGridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 14px;
  padding-bottom: 4rem;
  @media screen and (max-width: 650px) {
    gap: 8px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

export default PageSectionGridContainer
