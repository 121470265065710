import React from "react"
import styled from "styled-components"
import TextOverflowReadMore from "../../core/TextOverflowReadMore"
import { Label } from "../../sparklePage/bookings/BookingsModalContactFormElems"

const Content = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  p {
    margin-bottom: 0.4em;
    line-height: 1.5;
    a {
      color: var(--accent);
    }
  }
`

const ProductCustomerNotes = ({ onChange, notes }) => {
  return (
    <>
      <Content>
        <Label for="additionalInformation">
          <span>Add a note to the seller</span>
          <textarea
            name="additionalInformation"
            placeholder="optional"
            defaultValue={notes}
            onChange={(e) => onChange(e.target.value)}
          />
        </Label>
      </Content>
    </>
  )
}

export default ProductCustomerNotes
