import React from "react"
import IconEdit from "../../icons/IconEdit"
import BookingsText from "./BookingsText"
import styled, { keyframes } from "styled-components"
import { parseTimeForBookingsSummary, capitalizeFirstLetter } from "../utils"
import Tooltip from "../../core/Tooltip"

export const fadeInAndUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(3px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0px) translateZ(0);
  }
`

const Container = styled.div`
  display: flex;
  text-align: left;
  height: 100%;
  line-height: 1.2;
  font-weight: normal;
  font-size: 0.8rem;
  button {
    font-size: 0.8rem;
    /* Added specifically for iOS/Safari */
  }
  h4 {
    font-weight: bold !important;
    text-transform: uppercase;
    font-size: 11.5px !important;
    letter-spacing: 0.02em;
    margin-bottom: 0.3rem;
  }
  h3,
  p {
    margin-bottom: 0.2rem;
  }
  .animate-in {
    animation: ${fadeInAndUp} 0.3s;
  }
`

const BookingsModalSummary = ({
  timeZone,
  selectedBooking,
  bookingInfo,
  onEditTime,
  onBack,
  view,
  isGroupClass,
}) => {
  const { date, time, fromNow, appliedTimeZoneAbbreviation } =
    parseTimeForBookingsSummary(bookingInfo || {}) || {}
  if (!selectedBooking) return null

  const showTimeslot =
    bookingInfo &&
    view !== "calendar" &&
    view !== "description" &&
    view !== "group-class-list" &&
    view !== "manual-booking" &&
    view !== "manual-contact"
  return (
    <>
      <Container>
        <div style={{ maxWidth: 160 }} className={"animate-in"}>
          <BookingsText summary booking={selectedBooking} />
        </div>
        <div>
          {/* Waitlist/Request unavailable date/time slot */}
          {!showTimeslot && !isGroupClass && (
            <div
              className={"animate-in"}
              style={{
                opacity: 1,
                transform: "translateY(0)",
                pointerEvents: "",
                animation: "",
                marginLeft: 18,
                paddingLeft: 18,
              }}
            >
              <h4>Optional</h4>
              <p>Need a different time?</p>
              <button
                onClick={() => onEditTime(true)}
                style={{ textAlign: "left", color: "inherit" }}
              >
                Request another time
                <IconEdit
                  style={{
                    fill: "var(--accent)",
                    display: "inline-block",
                    width: "auto",
                    height: ".8em",
                    marginLeft: 5,
                  }}
                />
              </button>
            </div>
          )}
          {/* Timeslot info */}
          {/* NOTE: Rendering this even if timeslot isn't yet selected for less vertical jank - esp. since this is usually longer than the booking text */}
          <div
            className={"animate-in"}
            style={{
              position: "absolute",
              opacity: showTimeslot ? 1 : 0,
              transform: showTimeslot ? "translateY(0)" : 0,
              pointerEvents: showTimeslot ? "" : "none",
              animation: showTimeslot ? "" : "none",
              marginLeft: 18,
              paddingLeft: 18,
            }}
          >
            <h4>{date}</h4>
            <Tooltip style={{ marginTop: 3 }} text="your local timezone">
              {time} {appliedTimeZoneAbbreviation}
            </Tooltip>
            <p>{capitalizeFirstLetter(fromNow)}</p>
            {!isGroupClass && (
              <button
                onClick={() => onBack()}
                style={{ textAlign: "left", color: "inherit" }}
              >
                Edit timeslot
                <IconEdit
                  style={{
                    fill: "var(--accent)",
                    display: "inline-block",
                    width: "auto",
                    height: ".8em",
                    marginLeft: 5,
                  }}
                />
              </button>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

export default BookingsModalSummary
