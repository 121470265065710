import React from "react"
import useBookingInfoPending from "../hooks/useBookingInfoPending"
import useBookingPaidComplete from "../hooks/useBookingPaidComplete"
import Modals from "../core/Modals"
import { getSelectedBooking } from "../sparklePage/utils"
import { navigate } from "gatsby" // will need
import BookingsSuccess, { getBookingSuccessProps } from "./BookingsSuccess"

const PurchaseSuccessBooking = ({
  tenantInfo,
  bookingId,
  session_id,
  tenantUrl,
  errorComp,
}) => {
  const { bookingInfo, error } =
    useBookingInfoPending({
      bookingId,
    }) || {}
  const selectedBooking =
    getSelectedBooking({
      tenantInfo,
      bookingId: (bookingInfo || {}).appointmentTypeId,
    }) || {}

  // Here's booking price
  // const { price: bookingPrice } = selectedBooking
  // Using: price info from details especially for voluntary contributions
  // TODO: Does the detail info here matter at all or is bookingInfoPending enough?
  const { error: paidCompError, price } = useBookingPaidComplete({
    tenantInfo,
    bookingInfo,
    session_id,
  })

  // TODO: Handle Errors
  if (error || (paidCompError && paidCompError !== "manual")) return errorComp

  const customerInfo = { email: bookingInfo?.email, phone: bookingInfo?.phone }
  const onClose = () => {
    navigate(`/${(tenantUrl || "").toLowerCase()}/`)
  }

  return (
    <Modals
      {...getBookingSuccessProps(onClose)}
      loading={!selectedBooking.name || !price}
    >
      {/* TODO: Possibly also add cost??? */}
      <BookingsSuccess
        paid
        tenantInfo={tenantInfo}
        applyTimezone
        onClose={onClose}
        customerInfo={customerInfo}
        selectedBooking={{
          ...selectedBooking,
          price,
        }}
        bookingInfo={bookingInfo}
        useManual={paidCompError === "manual"}
      />
    </Modals>
  )
}

export default PurchaseSuccessBooking
