import React from "react"
import styled from "styled-components"
import he from "he"
import { renderToString } from "react-dom/server"
import { Submit } from "./BookingsModalContactFormElems"
import TextOverflowGradient from "../../core/TextOverflowGradient"
import Linkify from "react-linkify"
import { getArrFromParas } from "../../utils/text"

const BookingsModalDescription = ({ style, setView, selectedBooking }) => {
  const { description = "", isGroupClass = false } = selectedBooking || {}
  if (!description) return null

  return (
    <>
      <div
        style={{
          overflow: "auto",
          height: "100%",
          maxWidth: "90vw", // This is my somewhat solution for the fact the parent div/container is 700% width
          ...style,
        }}
      >
        {getArrFromParas(description).map((t, i) => {
          const linkifyDescription = <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}>{t}</Linkify>
          const linkifyDescriptionString = renderToString(linkifyDescription)

          // bypass linkify if there is a link or image tag (hotfix for now)
          const decodedHtmlString =
            (t.includes("<a href=") && t.includes("</a>")) ||
              (t.includes("<img ") && (t.includes("</img>") || t.includes("/>")))
              ? t
              : he.decode(linkifyDescriptionString)

          return (
            <p
              key={i}
              style={{
                lineHeight: 1.5,
                fontSize: ".98rem",
                marginBottom: "0.5em",
              }}
              dangerouslySetInnerHTML={{ __html: decodedHtmlString }}
            />
          )
        })}
        <Spacer />
      </div>
      <div
        style={{
          bottom: "0",
          backdropFilter: "blur(5px)",
          position: "sticky",
          backgroundColor: "rbga(255,255,255, 0.5)",
          isolation: "isolate",
        }}
      >
        <Submit
          onClick={() =>
            isGroupClass ? setView("group-class-list") : setView("calendar")
          }
        >
          Book
        </Submit>
      </div>
    </>
  )
}

export default BookingsModalDescription

const Spacer = styled.div`
  height: 2rem;
`
