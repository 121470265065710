import React, { useEffect } from "react"
import { parseBookingForSuccessAndCancel } from "../sparklePage/utils"
import Linkify from "react-linkify"
import ProductLineItem from "../sparklePage/store/ProductLineItem"
import { LineItemsContainer } from "../sparklePage/store/Cart"
import { Content } from "../sparklePage/bookings/BookingsSharedSuccessAndCancel"
import { segmentAction } from "../hooks/useSegmentTrack"

// So we can use the same modal as bookings but swap in diff props to get the smaller modal... this will prevent the black background from blinking in/out on 2 modals this will use just 1 modal instead
export const getBookingSuccessProps = (onClose, directNylasEmbed, selectedBooking) => {
  return {
    type: "prompt",
    primary: {
      children: "Close",
      onClick: () => { 
        if (directNylasEmbed) {          
          const { id, schedulingUrl } = selectedBooking || {}
          if (schedulingUrl) {
            window.location.href = `https://schedule.nylas.com/${schedulingUrl}?project=${id}&directNylasEmbed=true`
          } else {
            onClose()
          }
        } else {
          onClose()
        }         
      },
      style: { width: "100%" },
    },
    onClose,
  }
}

// Free bookings
const BookingsSuccess = ({
  customerInfo: { email, phone } = {},
  bookingInfo,
  tenantInfo,
  applyTimezone = false,
  selectedBooking,
  paid, // came here from a paid modal
  useManual = false,
  selectedBooking: { additionalConfirmation = "", price } = {},
  customTipValue = 0,
}) => {
  const text = parseBookingForSuccessAndCancel({
    bookingInfo,
    selectedBooking,
    applyTimezone,
  })
  const bookingId = (selectedBooking || {}).id

  const total = paid ? price + customTipValue : 0

  useEffect(() => {
    if (tenantInfo && bookingId) {
      segmentAction.track(
        `Sparkles Booking Success`,
        { bookingId },
        { tenantInfo }
      )
    }
  }, [tenantInfo, bookingId])

  return (
    <Content>
      <h2 style={{ marginBottom: ".5rem" }}>Thank you!</h2>
      <p style={{ marginBottom: "1rem" }}>
        {useManual
          ? `You submitted a booking request!`
          : `You created a successful booking!`}
      </p>
      <LineItemsContainer>
        <ProductLineItem
          quantity={1}
          price={total}
          hideTotal={true}
          itemType={"booking"}
          name={text[0]}
          descriptions={text.slice(1)}
        />
      </LineItemsContainer>
      {additionalConfirmation && (
        <p
          style={{
            marginTop: "1rem",
            borderBottom: "1px solid #eee",
            padding: "5px 0 15px",
            textAlign: "left",
          }}
        >
          <strong>Additional Information:</strong>
          <br />
          <Linkify>{additionalConfirmation}</Linkify>
        </p>
      )}
      <div
        style={{
          // marginTop: "1rem",
          fontSize: "0.875rem",
          display: "flex",
          justifyContent: "flex-end",
          paddingBlock: "0.5em",
        }}
      >
        {useManual ? (
          <>
            <span style={{ fontWeight: "bold", textAlign: "left" }}>
              Upon approval, confirmation will be sent to:
            </span>
          </>
        ) : (
          <>
            <span style={{ fontWeight: "bold" }}>Confirmations sent to: </span>
          </>
        )}
        <span
          style={{ marginBottom: "0", textAlign: "right", paddingLeft: "1ch" }}
        >
          {" "}
          {email}
          {phone && (
            <>
              <br />
              {phone}
            </>
          )}
        </span>
      </div>

      <p
        style={{
          paddingTop: "1rem",
          borderTop: "1px solid #eee",
        }}
      >
        <a
          href="https://sprkl.es/"
          onClick={() =>
            segmentAction.track("Confirmation Get Sparkle Click", {
              value: "get sparkle",
            })
          }
          target="_blank"
          rel={"noopener noreferrer"}
          style={{ fontWeight: "bold", textDecoration: "none" }}
        >
          Get your own ✨ Sparkle Site today →
        </a>
      </p>
    </Content>
  )
}

export default BookingsSuccess
