import React from "react"
import styled from "styled-components"
import { ButtonPrimary as BP } from "../../core/Buttons"
import ButtonLoaderAlone from "../../core/ButtonLoaderAlone"
import ButtonLoaderWithText from "../../core/ButtonLoaderWithText"

export const CheckBoxLabel = styled.label`
  font-weight: bold;
  padding: 10px 12px;
  border: 1px solid rgba(0, 0, 0, 0);
  &.error {
    border-color: var(--error) !important;
  }
  background: #f7f7f7;
  border-radius: 15px;
  font-size: 1rem;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #222;
  span {
    font-size: 13px;
  }
  :focus-within {
    border-color: #000;
  }
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }
  input[type="checkbox"] {
    cursor: pointer;
    font-size: 1rem;
    margin-right: 10px;
    flex: 0 24px;
    height: 24px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background: #f7f7f7;
    transition: 0.175s;
    outline: none;
    position: relative;
    :after {
      content: "";
      position: absolute;
      width: 6px;
      height: 11px;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      left: 8px;
      top: 4px;
      transform: rotate(43deg);
      opacity: 0;
    }
    @media screen and (max-width: 600px) {
      margin-right: 5px;
      flex: 0 20px;
      height: 20px;
      :after {
        left: 6px;
        top: 2px;
      }
    }
    :checked {
      background: ${({ theme }) => theme.colors.accent};
      border-color: ${({ theme }) => theme.colors.accent};
      :after {
        opacity: 1;
      }
    }
    :focus {
      border-color: #000;
    }
  }
`

// TODO: Possibly pull out into forms
export const Label = styled.label`
  text-align: left;
  display: block;

  font-size: 1.1rem;
  line-height: 1.5;
  position: relative;
  letter-spacing: 0.02em;

  span {
    z-index: 2;
    font-weight: bold;
    transition: 0.2s;
    opacity: 1;
    letter-spacing: 0.04em;
    white-space: wrap;
    display: block;
    font-size: 13px;
    padding: 0.025em;
    position: relative;
    text-wrap: wrap;
  }
  textarea {
    &::placeholder {
      margin: 1em 0;
    }
  }
  input:not(.react-tag-input__input),
  textarea,
  select,
  .react-tag-input {
    padding: 0.5em 12px 6px;
    border: 1px solid rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    transition: 0.2s;
    font-size: 1rem;
    color: #000;
    width: 100%;
    transition: 0.15s;
    line-height: 1.45;
    ::placeholder {
      font-style: italic;
      color: #777;
    }
    &.error {
      border-color: var(--error) !important;
    }
    &:focus,
    &:focus-within {
      outline: none;
      border-color: #000;
    }
  }
`

export const OptTipInput = styled.input`
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-variant-numeric: tabular-nums;
  margin-left: 0.25em;

  padding: 0.2em 0.25em 0.2em 0.5em;
  min-width: 5ch;
  font-size: 1rem;
  border-radius: 8px;

  @media screen and (max-width: 700px) {
    min-width: 5ch;
    margin-right: 5px;
  }
`

export const ReactTagInputContainer = styled(Label)`
  .react-tag-input {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
  }
  *:not(span) {
    font-size: 13px !important;
  }
  input,
  .react-tag-input__tag {
    padding: 5px 25px 5px 13px;

    border: 1px solid #ccc;
    letter-spacing: 0.02em;
    line-height: 1.45;
  }
  input {
    border-color: transparent;
    flex: 1;
    background: none;
    font-size: 1rem;
  }
  .react-tag-input__tag {
    position: relative;
    white-space: nowrap;
    margin-right: 5px;
    background: #fff;
    border-radius: 100px;
  }
  .react-tag-input__tag__remove {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 23px;
    cursor: pointer;
    :before,
    :after {
      content: "";
      position: absolute;
      width: 2px;
      height: 10px;
      background: #999;
      left: 50%;
      top: 50%;
      margin-left: -1px;
      margin-top: -5px;
      transform-origin: center;
    }
    :before {
      transform: rotate(45deg);
    }
    :after {
      transform: rotate(-45deg);
    }
  }
`

export const SubmitText = ({ isProcessing, ignorePayment, payment_type }) => {
  switch (payment_type) {
    case "required":
      if (ignorePayment)
        return isProcessing ? <ButtonLoaderAlone /> : "Continue"
      else
        return (
          <>
            {isProcessing ? "To Checkout" : "Checkout Now"}
            <ButtonLoaderWithText isProcessing={isProcessing} />
          </>
        )
    case "optional":
      if (ignorePayment)
        return isProcessing ? <ButtonLoaderAlone /> : "Continue"
      else
        return (
          <>
            {isProcessing ? "To Checkout" : "Continue"}
            <ButtonLoaderWithText isProcessing={isProcessing} />
          </>
        )
    case "free":
      return isProcessing ? <ButtonLoaderAlone /> : "Book It"
    case "contactOnly":
      return isProcessing ? <ButtonLoaderAlone /> : "Submit"
    default: {
      console.error("got an unknown type")
      return null
    }
  }
}

export const Submit = styled(BP)`
  display: block;
  width: 100%;
  font-size: 1.15rem;
  height: 3.5rem;
  :focus {
    border: 1px solid #000;
    outline: none;
  }
`
