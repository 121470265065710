import { useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"

const apiCode = process.env.GATSBY_SPARKLE_API_CODE
const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise

const stripeRedirect = async (sessionId) => {
  const stripe = await stripePromise
  const result = await stripe.redirectToCheckout({
    sessionId,
  })
  if (result.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
  }
}

const useBookingRedirectToStripe = ({
  customerInfo,
  pendingPaymentId,
  tenantInfo: {
    tenantId,
    tenantPaymentAccounts,
    tenantGroupClasses,
    tenantAppointmentTypes,
  } = {},
  bookingInfo: { graph, project } = {},
  bookingInfo: { project: { id: projectId } = {} } = {},
  onProcessing,
  onDone,
  selectedBooking,
  voluntaryContribution,
  customTipValue,
  includeTip,
}) => {
  // Load correct stripe account  (with correct id)
  useEffect(() => {
    if (
      tenantPaymentAccounts &&
      tenantPaymentAccounts.find(({ isDefault }) => isDefault === true)
    ) {
      // Default stripe id associated with account
      const { id: defaultStripeId } =
        tenantPaymentAccounts.find(({ isDefault }) => isDefault === true) || {}
      let stripeAccount = defaultStripeId || null

      // Possible payment line item stripe id override
      if (projectId) {
        if (graph === "group_customer") {
          // Group bookings
          const { tenantPaymentAccounts, payment_account_id } =
            tenantGroupClasses.find(({ id }) => id === projectId) || {}
          if (tenantPaymentAccounts) stripeAccount = payment_account_id
        } else {
          // Individual bookings
          const { payment_account_id } =
            tenantAppointmentTypes.find(({ id }) => id === projectId) || {}
          if (payment_account_id) stripeAccount = payment_account_id
        }
      }

      // Set up stripe
      stripePromise = loadStripe(
        `${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`,
        {
          stripeAccount,
        }
      )
    }
  }, [
    projectId,
    project,
    graph,
    tenantPaymentAccounts,
    tenantGroupClasses,
    tenantAppointmentTypes,
  ])

  if (!tenantPaymentAccounts) return // exit if no pay methods

  const onStripeRedirect = async () => {
    if (onProcessing) onProcessing()
    const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
    api
      .post(`CreateStripeCheckoutSession?code=${apiCode}&v=2`, {
        tenantId,
        bookingId: pendingPaymentId,
        isGroupBooking: graph === "group_customer",
        appointmentTypeId: selectedBooking.id,
        customerEmail: customerInfo.email,
        voluntaryContribution,
        customerMetadata: customerInfo.metadata || null,
        customTipValue: includeTip ? customTipValue : null, // passing in new value ("line item")
      })
      .then((response) => {
        stripeRedirect(response.data.id)
      })
      .catch((error) => {
        console.log(error)
        if (onDone) onDone()
      })
      .finally(() => {})
  }

  return onStripeRedirect
}

export default useBookingRedirectToStripe
