import React, { useState, useEffect } from "react"
import axios from "axios"
import queryString from "query-string"
import Modals from "../../core/Modals"
import IconLoaderDots from "../../icons/IconLoaderDots"
import useBookingInfo from "../../hooks/useBookingInfo"
import { parseBookingForSuccessAndCancel, clearBooking } from "../utils"
import {
  Content,
  BookingsModalIconCalendar,
} from "./BookingsSharedSuccessAndCancel"
import { segmentAction } from "../../hooks/useSegmentTrack"

const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
const apiCode = process.env.GATSBY_SPARKLE_API_CODE

const MODAL_LOAD = "load" // first display
const MODAL_INPROGRESS = "in-progress" // submitting
const MODAL_SUCCESS = "success"
const MODAL_ERROR = "error"

const getModalData = ({
  bookingId,
  bookingCustomerId,
  tenantInfo,
  state,
  bookingState,
  onClose,
  setState,
  onPrimary /* Really only our first onSubmit */,
}) => {
  let primary = {}
  let secondary = { onClick: onClose }
  let title = "Cancel this booking?"
  switch (state) {
    case MODAL_LOAD: {
      primary = {
        children: "Yes",
        onClick: () => {
          segmentAction.track(
            `Sparkles Booking Cancel Click`,
            { bookingId, bookingCustomerId },
            { tenantInfo }
          )
          setState(MODAL_INPROGRESS)
          onPrimary()
        },
        status: "error",
      }
      secondary = {
        onClick: () => {
          segmentAction.track(
            `Sparkles Booking Not Cancel Click`,
            { bookingId, bookingCustomerId },
            { tenantInfo }
          )
          onClose()
        },
        children: "No",
      }
      break
    }
    case MODAL_INPROGRESS: {
      primary = {
        ...primary,
        className: "loading",
        status: "error",
        onClick: () => {
          setState(MODAL_SUCCESS)
        },
        children: <IconLoaderDots style={{ width: "auto", height: 8 }} />,
        style: { width: "100%" },
      }
      secondary = {
        ...secondary,
        style: { width: "0", padding: 0 },
      }
      title = "Canceling..."
      break
    }
    case MODAL_SUCCESS: {
      segmentAction.track(
        `Sparkles Booking Canceled`,
        { bookingId, bookingCustomerId },
        { tenantInfo }
      )
      primary = {
        ...primary,
        onClick: onClose,
        children: "Close",
        style: { width: "100%" },
        status: "gray",
      }
      secondary = {
        ...secondary,
        style: { width: "0", padding: 0 },
      }
      title = (
        <>
          Complete!
          <span
            style={{
              display: "block",
              marginTop: 10,
              fontSize: "1rem",
              fontWeight: "normal",
            }}
          >
            Booking has been canceled.
          </span>
        </>
      )
      break
    }
    case MODAL_ERROR: {
      primary = {
        ...primary,
        onClick: onClose,
        children: "Close",
        style: { width: "100%" },
        status: "gray",
      }
      secondary = {
        ...secondary,
        style: { width: "0", padding: 0 },
      }
      title = (
        <>
          Whooops!
          <span
            style={{
              display: "block",
              marginTop: 10,
              fontSize: "1rem",
              fontWeight: "normal",
            }}
          >
            Something went wrong while trying to cancel. Please try again.
          </span>
        </>
      )
      break
    }
    default:
      break
  }
  if (bookingState === "completed") {
    primary = {
      ...primary,
      onClick: onClose,
      children: "Got It",
      style: { width: "100%" },
      status: "primary",
    }
    secondary = {}
    title = (
      <>
        Oooops!
        <span
          style={{
            display: "block",
            marginTop: 10,
            fontSize: "1rem",
            fontWeight: "normal",
          }}
        >
          This booking is in the past and can no longer be canceled.
        </span>
      </>
    )
  }
  if (bookingState.includes("cancel")) {
    primary = {
      ...primary,
      onClick: onClose,
      children: "Got It",
      style: { width: "100%" },
      status: "primary",
    }
    secondary = {}
    title = (
      <>
        No problem!
        <span
          style={{
            display: "block",
            marginTop: 10,
            fontSize: "1rem",
            fontWeight: "normal",
          }}
        >
          Booking has already been canceled.
        </span>
      </>
    )
  }
  return { title, secondary, primary }
}

const BookingsCancel = ({ location: { search } = {}, tenantInfo }) => {
  const { cancelBookingId: bookingId, name, email } = queryString.parse(search)
  const [state, setState] = useState(MODAL_LOAD) // could be useReducer
  const onClose = () => {
    clearBooking()
  }
  const bookingInfo = useBookingInfo({
    bookingId,
    tenantInfo
  })

  const {
    state: bookingState, // "confirmed", "completed" Other types: https://developers.timekit.io/reference#instant-graph
  } = bookingInfo || {}
  useEffect(() => {
    if (
      tenantInfo &&
      bookingInfo &&
      bookingInfo.project &&
      bookingInfo.project.id &&
      state === "load" &&
      bookingId
    ) {
      segmentAction.track(
        `Sparkles Booking Cancel Modal`,
        { bookingId: bookingInfo.project.id, bookingCustomerId: bookingId },
        { tenantInfo }
      )
    }
  }, [tenantInfo, bookingId, bookingInfo, state])

  if (!bookingId || !bookingInfo) return null // exit
  const timeZone = (bookingInfo || {})?.customers?.[0]?.timezone
  const text = bookingState.includes("cancel")
    ? ""
    : parseBookingForSuccessAndCancel({
        bookingInfo: { ...bookingInfo, timeZone },
        applyTimezone: true,
      })
  const { title, secondary, primary } = getModalData({
    state,
    bookingId: bookingInfo && bookingInfo.project && bookingInfo.project.id,
    tenantInfo,
    bookingCustomerId: bookingId,
    bookingState,
    onClose,
    setState,
    onPrimary: () => {
      if (bookingId) {
        // TODO: Should we allow to cancel an in-flight request?
        const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
        api
          .post(`CancelBooking?code=${apiCode}`, {
            tenantId: tenantInfo.tenantId,
            cancelBookingId: bookingId,
            email: (email ?? "").replace(' ', '+')
          })
          .then(() => {
            setState(MODAL_SUCCESS)
          })
          .catch((error) => {
            console.log(error)
            setState(MODAL_ERROR)
          })
      }
    },
  })
  return (
    <Modals
      primary={primary}
      secondary={secondary}
      onClose={() => {
        segmentAction.track(
          `Sparkles Booking Not Cancel Click`,
          { bookingId: bookingInfo.project.id, bookingCustomerId: bookingId },
          { tenantInfo }
        )
        onClose()
      }}
      icon={<BookingsModalIconCalendar />}
    >
      <Content>
        {![MODAL_SUCCESS, MODAL_ERROR].includes(state) && name && (
          <h3>Hi {name}</h3>
        )}
        <h2>{title}</h2>
        {![MODAL_SUCCESS, MODAL_ERROR].includes(state) && text.length > 0 && (
          <div className={"booking"} style={{ marginBottom: 0 }}>
            {text.map((str, i) => {
              if (i === 0)
                return (
                  <p key={i} style={{ fontWeight: "bold" }}>
                    {str}
                  </p>
                )
              return <p key={i}>{str}</p>
            })}
          </div>
        )}
      </Content>
    </Modals>
  )
}

export default BookingsCancel
