import React, { useState, useEffect } from "react"
import axios from "axios"
import { useLocation } from "@reach/router"
import styled from "styled-components"
import BookingsModalSummary from "./BookingsModalSummary"
import Modals from "../../core/Modals"
import { ButtonPrimary } from "../../core/Buttons"
import Content from "./BookingsModalContent"
import queryString from "query-string"
import { navigate } from "gatsby"
import useWidgetModeContext from "../../hooks/useWidgetModeContext"
import {
  timekitHeightPadding,
  summaryHeight,
} from "../../hooks/useTimekitWidgetSet"
import moment from "moment-timezone"

const Main = styled.div`
  // don't think can use this because of the iframe
  .BookingMainView .top-bar {
    display: none;
  }
`

const BookingsModalNylas = ({
  selectedBooking,
  timekitHeight,
  setView,
  setBookingInfo,
  setTimeZone,
  timezone,
  iframeIndex,
  onEditTime,
}) => {
  const [noSlots, setNoSlots] = useState(false)
  const location = useLocation()
  const {
    calendar_id,
    account_id,
    start_time,
    end_time,
    name,
    email,
    phone,
    description: notes,
    cancelBookingId,
    project,
    resource,
    directNylasEmbed,
  } = queryString.parse(location.search)

  // Pass on querystring
  const queryStringArray = []
  if (name) {
    queryStringArray.push(`name=${name}`)
  }
  if (email) {
    queryStringArray.push(`email=${email}`)
  }
  if (phone) {
    queryStringArray.push(`phone=${phone}`)
  }
  if (notes) {
    queryStringArray.push(`notes=${notes}`)
  }
  if (cancelBookingId) {
    queryStringArray.push(`cancelBookingId=${cancelBookingId}`)
  }
  if (project) {
    queryStringArray.push(`project=${project}`)
  }
  if (resource) {
    queryStringArray.push(`resource=${resource}`)
  }
  if (directNylasEmbed) {
    queryStringArray.push(`directNylasEmbed=${directNylasEmbed}`)
  }

  const iFrameQueryString = queryStringArray.join("&")

  // Keep modal a standard height... contact info might grow
  // Could use a diff method to ensure same height like: https://codesandbox.io/s/grow-to-the-largest-height-carousel-00sdf
  const contentStyle = {
    height: timekitHeight + timekitHeightPadding, // Prevent scrolling areas that don't need to be there especially on mobile
  }

  useEffect(() => {
    if (window) {
      // Via iframe message
      window.addEventListener("message", function (event) {
        if (event.data.event_id === "nylas_contact_information_post") {
          const nylasBookingInfo = {
            ...event.data.data.nylasBookingInfo,
            resources: [
              { ...event.data.data.nylasBookingInfo.resources[0], timezone },
            ],
            start: moment(event.data.data.nylasBookingInfo.start),
            end: moment(event.data.data.nylasBookingInfo.end),
            timezone,
          }

          setBookingInfo(nylasBookingInfo)
          setTimeZone(nylasBookingInfo.timezone)
          setView("contact")
        }
      })

      // Via query string
      if (start_time && end_time && calendar_id && project) {
        const nylasBookingInfo = {
          resources: [{ ...{ id: calendar_id }, timezone }],
          start: moment.unix(start_time),
          end: moment.unix(end_time),
          timezone,
        }

        setBookingInfo(nylasBookingInfo)
        setTimeZone(nylasBookingInfo.timezone)
        setView("contact")
      }
    }

    // useEffect cleanup
    return () => {
      if (window) {
        window.removeEventListener("message", function () {})
      }
    }
  }, [])

  useEffect(() => {
    if (selectedBooking && selectedBooking.schedulingUrl) {
      // Check if all slots are filled
      const api = axios.create({
        baseURL: `https://api.schedule.nylas.com/schedule/`,
      })
      api
        .get(`${selectedBooking.schedulingUrl}/timeslots?locale=en`)
        .then((response) => {
          if (response) {
            const { data } = response || []

            if (data.length === 0) {
              setNoSlots(true)
            }
          }
        })
        .catch(() => {
          setNoSlots(true)
        })
    }
  }, [selectedBooking])

  return (
    <>
      {!noSlots && selectedBooking && selectedBooking.schedulingUrl && (
        <iframe
          key={iframeIndex}
          id="nylas-scheduler-iframe"
          src={`https://schedule.nylas.com/${selectedBooking.schedulingUrl}?${iFrameQueryString}`}
          title="Scheduling"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      )}
      {noSlots && (
        <>
          <h4>Sorry, no available automatic bookings</h4>
          <p>Need to request a time anyways?</p>
          <ButtonPrimary
            type={"button"}
            style={{ marginTop: 10 }}
            onClick={() => onEditTime(true)}
          >
            Request Time
          </ButtonPrimary>
        </>
      )}
    </>
  )
}

export default BookingsModalNylas
