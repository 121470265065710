import { useEffect, useState } from "react"
import axios from "axios"

const useMaxedOutBookings = ({ tenantInfo: { tenantUrl, tenantAppointmentService } } = {}) => {
  // Maxed out data... finding days with too many bookings (to NOT show)
  const [maxedOutDays, setMaxedOutDays] = useState(null)
  const [maxedOutDaysByAppointmentType, setMaxedOutDaysByAppointmentType] = useState(null)
  const [error, setError] = useState(false)
  const [refreshMaxedOutDays, setRefreshMaxedOutDays] = useState(true) // start as true to start cycle
  useEffect(() => {
    if (refreshMaxedOutDays && tenantUrl) {
      if (tenantAppointmentService === "Timekit" || tenantAppointmentService === "timekit") {
        // maxed out day
        const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
        const api = axios.create({ baseURL: GATSBY_SPARKLE_API_PATH })
        let apiCode = process.env.GATSBY_SPARKLE_API_CODE
        api
          .get(`GetMaxedOutDays?code=${apiCode}&tenant_name_as_url=${tenantUrl}`)
          .then((response) => {
            // handle success
            const { data = {} } = response
            setMaxedOutDays(data.maxedOutDays || [])
            setMaxedOutDaysByAppointmentType(data.maxedOutDaysByAppointmentType || [])
            setRefreshMaxedOutDays(false)
          })
          .catch((error) => {
            console.log(error)
            setMaxedOutDays([]) // just so we can go on... and not fail
            setMaxedOutDaysByAppointmentType([]) // just so we can go on... and not fail
            setError(true)
          })
          .finally(() => {
            setRefreshMaxedOutDays(false) // double check
          })
      } else {
        setMaxedOutDays([]) // just so we can go on... and not fail
        setMaxedOutDaysByAppointmentType([]) // just so we can go on... and not fail
        setRefreshMaxedOutDays(false);
      }      
    }    
  }, [refreshMaxedOutDays, tenantUrl])

  return {
    error,
    maxedOutDays,
    maxedOutDaysByAppointmentType,
    maxedOutDaysLoaded: maxedOutDays !== null,
    setRefreshMaxedOutDays, // To be used when a booking is completed (may now max out a day)
  }
}

export default useMaxedOutBookings
