import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import axios from "axios"
import isEmpty from "lodash/isEmpty"
import styled from "styled-components"
import { ButtonLinks, ButtonBase, ButtonPrimary } from "../core/Buttons"
import { segmentAction, useSegmentTrackLoad } from "../hooks/useSegmentTrack"
import useTenantInfoContext from "../hooks/useTenantInfoContext"
import useTenantSignedInContext from "../hooks/useTenantSignedInContext"
import useWidgetModeContext from "../hooks/useWidgetModeContext"
import { parseLinks } from "../icons/IconLinks"
import Downloads from "./downloads/Downloads"
import { Content } from "./layout/Content"
import PageBookings from "./PageBookings"
import PageFollowShare from "./PageFollowShare"
import PageHeader from "./PageHeader"
import PageShop from "./PageShop"
import Support from "./support/Support"
import { getShowBookings } from "./utils"

// Inspired by https://stackoverflow.com/a/11300963/2824643
const getValidUrl = (url = "") => {
  let next = url.trim() // remove white space + lowercase

  // Check if begins with https:// or http:// or // -- all acceptable also case insensitive with "i"
  const pattern = new RegExp("^(https?://|//)", "gi")
  const res = pattern.test(next)
  return res ? next : "//" + next
}

const Page = ({ tenantUrl, setOverrideAccent }) => {
  // TODO: Make anchor links for each section instead?
  const tenantInfo = useTenantInfoContext()
  const { user, tenantSignedIn, onOwnSite, adminUrl } =
    useTenantSignedInContext()
  const location = useLocation()
  const [socialLinks, regLinks] = parseLinks(tenantInfo?.tenantAdditionalLinks)
  const widgetMode = useWidgetModeContext()
  const numDownloads = (tenantInfo.tenantSendFileActions || []).length

  useEffect(() => {
    if (tenantInfo && tenantInfo.tenantThemeAccent) {
      setOverrideAccent(tenantInfo.tenantThemeAccent)
    }
  }, [tenantInfo, setOverrideAccent])

  useSegmentTrackLoad(
    "Sparkles",
    tenantUrl,
    {
      tenantWidgetMode: widgetMode,
      tenantName: tenantInfo.tenantName,
      tenantUrl,
    } // saving
  )

  // track clicks
  const handleTrackLinkClicked = (url) => {
    if (tenantInfo && tenantInfo.tenantId) {
      // api call
      const baseURL = process.env.GATSBY_SPARKLE_API_PATH
      const apiCode = process.env.GATSBY_SPARKLE_API_CODE
      const api = axios.create({ baseURL })

      api
        .post(`UpdateAdditionalLinks?code=${apiCode}`, {
          tenantId: tenantInfo && tenantInfo.tenantId, additionalLinksToSave: {
            url: url,
            publicClicked: true
          }
        })
        .catch(() => {
          // TODO: Handle error
          console.error("handleTrackLinkClicked did not work...")
        })
    }
  }

  // Exit early with just booking stuff (widgetMode)
  if (widgetMode)
    return (
      <PageBookings
        tenantInfo={tenantInfo}
        location={location}
        BookingsGridContainer={({ children }) => (
          <Content style={{ padding: "15px 10px" }}>{children}</Content>
        )}
      />
    )

  // TODO: Each time signed in could point them to do more
  const hasContributionProduct = !!tenantInfo?.tenantContributionProduct?.name
  const hasBookings = getShowBookings({ tenantInfo })
  const hasProducts =
    (tenantInfo?.tenantProducts?.filter((o) => !o.hidePublic) || []).length > 0
  const hasHiddenProducts =
    (tenantInfo?.tenantProducts?.filter((o) => o.hidePublic) || []).length > 0

  const hiddenProductLength = (
    tenantInfo?.tenantProducts?.filter((o) => o.hidePublic) || []
  ).length
  const totalProductLength = tenantInfo?.tenantProducts?.length

  // Check if has charges enabled stripe account that isn't the default we sparkle account
  const weSparkleAccountId = "acct_1NcWTPIxfZKreatf"
  const hasChargesEnabledPaymentAccount =
    (tenantInfo?.tenantPaymentAccounts || []).findIndex(
      ({ isDefault, chargesEnabled, id }) =>
        id !== weSparkleAccountId &&
        isDefault === true &&
        chargesEnabled === true
    ) > -1

  // Needs Stripe!
  const looksLikeNeedsStripe =
    (hasContributionProduct || hasProducts || hasBookings) &&
    !hasChargesEnabledPaymentAccount

  // hide Shop section if products is empty (!hasProducts) and if all products are hidden (hasHiddenProducts && hiddenProductLength === totalProductLength)
  const hideShopSection =
    !hasProducts ||
    (hasHiddenProducts && hiddenProductLength === totalProductLength)

  const hasLinksOrDownloads =
    regLinks.length > 0 || (tenantInfo.tenantSendFileActions || []).length > 0
  const looksEmpty = !hasBookings && !hasProducts && !hasLinksOrDownloads

  const hasOnlyLinks = !hasBookings && !hasProducts && !numDownloads

  // Doesn't make the site look less empty so doesn't go into "looksEmpty"
  const hasSupport =
    tenantInfo.tenantContributionProduct &&
    tenantInfo.tenantContributionProduct.id

  return (
    <>
      <PageHeader
        tenantUrl={tenantUrl}
        tenantInfo={tenantInfo}
        socialLinks={socialLinks}
        handleTrackLinkClicked={handleTrackLinkClicked}
      />
      <Section>
        {looksEmpty && onOwnSite && !isEmpty(tenantSignedIn) && (
          <EmptyContent>
            <div
              style={{
                background: "#E3EFF3",
                border: "2px dashed #07234C",
                borderRadius: "10px",
                padding: "1em 2em",
              }}
            >
              <span
                style={{
                  textAlign: "right",
                  float: "right",
                  fontSize: "0.8em",
                }}
              >
                (Don't worry, only you can see this section)
              </span>
              <div style={{ paddingTop: "2em" }}>
                <h3>Yay! You've made a Sparkle Site! 🎉</h3>
                <p>
                  Let's get started! Establish your presence by adding some
                  links, products or bookings to the page.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <InternalButton
                    as={"a"}
                    href={adminUrl}
                    onClick={() =>
                      segmentAction.track("Sparkles Body Settings Click")
                    }
                  >
                    Let's go! ✨
                  </InternalButton>
                </div>
              </div>
            </div>
          </EmptyContent>
        )}
        {looksLikeNeedsStripe && onOwnSite && !isEmpty(tenantSignedIn) && (
          <EmptyContent>
            <div
              style={{
                background: "#E3EFF3",
                border: "2px dashed #07234C",
                borderRadius: "10px",
                padding: "1em 2em",
              }}
            >
              <span
                style={{
                  textAlign: "right",
                  float: "right",
                  fontSize: "0.8em",
                }}
              >
                (Don't worry, only you can see this section)
              </span>
              <div style={{ paddingTop: "2em" }}>
                <h3>
                  Nice! You've added products and/or services to your site. 🎉
                </h3>
                <p>
                  Now let's connect your bank account so you can get paid! ✨
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <InternalButton
                    as={"a"}
                    href={`${adminUrl}#payments`}
                    onClick={() =>
                      segmentAction.track(
                        "Sparkles Body Payment Settings Click"
                      )
                    }
                  >
                    Set Up Payments →
                  </InternalButton>
                </div>
              </div>
            </div>
          </EmptyContent>
        )}
        <PageBookings
          tenantInfo={tenantInfo}
          location={location}
          BookingsGridContainer={({ children }) => (
            <Content>
              <PageSectionTitle>Bookings</PageSectionTitle>
              {children}
            </Content>
          )}
        />
        {/* PRODUCTS */}

        <Content hidden={hideShopSection}>
          <PageSectionTitle>Shop</PageSectionTitle>
          <PageShop tenantInfo={tenantInfo} user={user} location={location} />
        </Content>

        {hasLinksOrDownloads && (
          <Content style={{ padding: "0 var(--content-pad)" }}>
            {/* DOWNLOADS */}
            {numDownloads > 0 && (
              <>
                <PageSectionTitleCenter>Downloads</PageSectionTitleCenter>
                <Downloads
                  tenantId={tenantInfo.tenantId}
                  tenantSendFileActions={tenantInfo.tenantSendFileActions}
                />
              </>
            )}
            {/* LINKS */}
            {regLinks.length > 0 && (
              <>
                <PageSectionTitleCenter>
                  {hasOnlyLinks ? " " : "Links"}
                </PageSectionTitleCenter>
                {regLinks
                  .sort((a, b) => a.order - b.order)
                  .map(({ url, name }, i) => {
                    const validUrl = getValidUrl(url)
                    return (
                      <ButtonLinks
                        onClick={() =>
                          handleTrackLinkClicked(validUrl)
                        }
                        style={{ marginBottom: 9 }}
                        key={i}
                        as={"a"}
                        href={validUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {name}
                      </ButtonLinks>
                    )
                  })}
              </>
            )}
          </Content>
        )}

        {/* SOCIAL @ bottom, only show if we have some other content too */}
        {socialLinks.length > 0 && (
          <Content
            style={{
              padding: "0 var(--content-pad)",
              textAlign: "center",
              paddingBlock: "2rem",
            }}
          >
            <PageSectionTitleCenter>Social Media</PageSectionTitleCenter>
            <PageFollowShare
              socialLinks={socialLinks}
              tenantUrl={tenantUrl}
              tenantName={tenantInfo?.tenantName}
              tenantImageUrl={tenantInfo?.tenantImageUrl}
              handleTrackLinkClicked={handleTrackLinkClicked}
            />
          </Content>
        )}
      </Section>
      {hasSupport && <Support tenantInfo={tenantInfo} />}
    </>
  )
}

export default Page

/**
 *
 *  STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 *
 */

const EmptyContent = styled(Content)`
  margin-top: 3rem;

  * {
    margin-bottom: 1rem;
    line-height: 1.2;
  }
  h2 {
    font-weight: bold;
    font-size: 2rem;
  }
  h3 {
    font-weight: bold;
    font-size: 1.5rem;
  }
  p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  a {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 800px) {
    margin-top: 2rem;
    text-align: center !important;
    * {
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
    h2 {
      font-size: 1.4rem;
    }
    h3 {
      font-size: 1.2rem;
    }
    p {
      margin-bottom: 1rem;
    }
    p,
    a {
      font-size: 1rem;
    }
  }
`
const Section = styled.section`
  min-height: 50vh;
  /* padding: 0rem 0rem 16rem; */
`
const PageSectionTitle = styled.h2`
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.8rem;
  letter-spacing: 0.03em;
  margin-bottom: 0.5rem;
  margin-top: 3.5rem;
  text-transform: uppercase;
  @media screen and (max-width: 800px) {
    margin-top: 3rem;
  }
`
const PageSectionTitleCenter = styled(PageSectionTitle)`
  text-align: center;
  margin-bottom: 2rem;
`

const InternalButton = styled(ButtonBase)`
  color: #fff;
  background: var(--wesparkle-drkblue);
  padding: 0.85em 1.75em;

  border-radius: 300px; /* absurdly large, but could make bigger */
  font-weight: bold;
  transition: 0.2s;
`
