import React, { useRef, useState } from "react"
import styled from "styled-components"
import { ButtonSocial } from "../core/Buttons"
import IconLinks from "../icons/IconLinks"
import IconShare from "../icons/IconShare"
import Share from "../core/Share"
import { segmentAction } from "../hooks/useSegmentTrack"

const Outter = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 25px;
  @media screen and (max-width: 800px) {
    gap: 10px; /* Since no text, match smaller Group gap */
  }
`
const Group = styled(Outter)`
  gap: 10px;
  @media screen and (max-width: 800px) {
    /* Turn off headers just use btns */
    .text {
      display: none;
    }
  }
`

const PageFollowShare = ({
  socialLinks,
  tenantUrl,
  tenantName,
  tenantImageUrl,
  handleTrackLinkClicked,
}) => {
  const shareTarget = useRef(null)
  const [showShare, setShowShare] = useState(false)
  return (
    <Outter>
      {socialLinks.length > 0 && (
        <Group>
          <span
            className={"text"}
            style={{
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            Follow
          </span>
          {socialLinks.map(({ url, name }, i) => {
            return (
              <ButtonSocial
                key={i}
                style={{
                  padding: 0,
                  width: 40,
                  height: 40,
                  display: "inline-flex",
                  borderRadius: "1000px",
                }}
                as={"a"}
                onClick={() =>
                  handleTrackLinkClicked(url)
                }
                href={url}
                rel="noopener noreferrer"
                target="_blank"
                title={name}
              >
                <IconLinks
                  style={{
                    margin: "auto",
                    width: 18,
                    height: 18,
                    fill: "currentColor",
                  }}
                  name={name}
                />
              </ButtonSocial>
            )
          })}
        </Group>
      )}
      <Group>
        <span
          className={"text"}
          style={{
            fontWeight: "bold",
            fontSize: 14,
          }}
        >
          Share
        </span>
        <ButtonSocial
          onMouseLeave={() => {
            setShowShare(() => false)
          }}
          onMouseEnter={() => {
            setShowShare(() => true)
          }}
          onPointerDown={() => {
            // Happens before enter/leave which is nice
            setShowShare((v) => {
              return !v
            })
          }}
          ref={shareTarget}
          style={{
            padding: 0,
            width: 40,
            height: 40,
            display: "inline-flex",
            borderRadius: "40px",
            alignItems: "center",
          }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <IconShare
            style={{
              margin: "auto",
              width: 18,
              height: "auto",
              fill: "currentColor",
            }}
            name={`Share ${1}`} // TODO: Tenant name
          />
        </ButtonSocial>
      </Group>
      <Share
        onOptClick={(value) => {
          segmentAction.track("Sparkles Share Click", { value })
        }}
        target={shareTarget && shareTarget.current}
        name={`${tenantName} Site`}
        media={tenantImageUrl}
        url={`${process.env.GATSBY_SPARKLE_PATH}${tenantUrl}`}
        show={showShare}
        setShow={setShowShare}
      />
    </Outter>
  )
}

export default PageFollowShare
