import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import useTenantSignedInContext from "../../hooks/useTenantSignedInContext"

const TenantAuthentication = ({ tenantUrl }) => {
  const {
    tenantSignedIn = {},
    homepageUrl,
    loading,
    onLogIn,
  } = useTenantSignedInContext()

  useEffect(() => {
    if (!loading) {
      const tenantIsSignedIn = tenantSignedIn?.tenantName

      if (!tenantIsSignedIn) {
        // console.log(`triggering login ${tenantUrl}`)
        onLogIn(`/${tenantUrl}/`)
      } else {
        // console.log(`already logged in - redirect to sparkle site ${tenantUrl}`)
        navigate(`/${tenantUrl}/`)
      }
    }
  }, [tenantSignedIn, loading])

  return null
}

export default TenantAuthentication
