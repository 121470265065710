import React from "react"
import styled from "styled-components"

const ToolTipText = styled("span")({
  visibility: "hidden",
  width: "120px",
  backgroundColor: "rgba(0,0,0,0.75)",
  color: "#fff",
  textAlign: "center",
  borderRadius: "6px",
  padding: "5px 0",
  position: "absolute",
  zIndex: 1,
  top: "-5px",
  left: "105%",
  opacity: 0,
  transition: "opacity 0.8s",
  ":after": {
    content: '""',
    position: "absolute",
    top: "25%",
    right: "100%",

    borderWidth: "5px",
    borderStyle: "solid",
    borderColor: "rgba(0,0,0,0.75) transparent transparent transparent",
    transform: "rotate(90deg)",
  },
  // media queries
  "@media screen and (max-width: 600px)": {
    // position at the bottom
    top: "110%",
    left: "50%",
    marginTop: "10px",
    ":after": {
      top: "-24%",
      left: "50%",
      transform: "rotate(180deg)",
    },
  },
})

const TooltipContainer = styled("button")({
  position: "relative",
  display: "inline-block",
  borderBottom: "1px dotted black",
  cursor: "help",

  ":hover span, :active span": {
    visibility: "visible",
    opacity: 1,
  },
})

const Tooltip = ({ children, text }) => (
  <TooltipContainer aria-haspopup="true">
    {children}
    <ToolTipText>{text}</ToolTipText>
  </TooltipContainer>
)

export default Tooltip
