import React, { useState } from "react"
import styled, { keyframes } from "styled-components"

const fadeInAndUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(3px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0px) translateZ(0);
  }
`
const H3 = styled.h3`
  /* margin-top: 20px; */
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0px;
  @media screen and (max-width: 600px) {
    margin-top: 0px;
  }
`
const Container = styled.div`
  background: #fff;
  animation: ${fadeInAndUp} 0.3s 0.3s both;
`

const BookingsContent = ({
  title,
  view,
  children,
  noHeadingPadding = false,
  contentStyle = {},
  headerProps = {},
  style = {},
  ...props
}) => {
  const [animationViewComplete, setAnimationComplete] = useState(null)
  return (
    <Container
      onAnimationEnd={(e) => {
        // After animation is done of animating in
        if (e.target === e.currentTarget) {
          setAnimationComplete(view)
        }
      }}
      style={{
        ...style,
        ...(animationViewComplete === view && {
          animation: "none",
          WebkitAnimation: "none",
        }),
      }}
      {...props}
    >
      {title && <H3 {...headerProps}>{title}</H3>}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          ...contentStyle,
        }}
      >
        {!noHeadingPadding && <div style={{ flex: "0 20px" }} />}
        {children}
      </div>
    </Container>
  )
}

export default BookingsContent
