import React, { useState } from "react"
import styled from "styled-components"
import ProductImage from "./ProductImage"
import Price from "./ProductPrice"
import { ButtonPrimary } from "../../core/Buttons"
import ProductOutOfStock from "./ProductOutOfStock"
import useProductMaxQty from "../../hooks/useProductMaxQty"
import useCartPrePayment from "../../hooks/useCartPrePayment"
import ProductQuantitySelect from "./ProductQuantitySelect"
import ProductStockText from "./ProductStockText"
import ProductShippingText from "./ProductShippingText"
import ProductPickupText from "./ProductPickupText"
import ProductPageDescriptionText from "./ProductPageDescriptionText"
import ProductCustomerNotes from "./ProductCustomerNotes"
import ProductShare from "./ProductShare"
import { segmentAction } from "../../hooks/useSegmentTrack"

/* All @media aligns with Modal */
const borderRadius = "10px"
const Container = styled.div`
  width: 57.5%;
  @media screen and (max-width: 730px) {
    width: 100%;
  }
`
const ImageContainer = styled.div`
  padding: 0px;
  margin: 0px;
  width: 100%;
  background: transparent;
  position: relative;
  border-radius: ${borderRadius};
  border: 1px solid rgba(0, 0, 0, 0.075);
  overflow: hidden;
  :before {
    /* Making a square */
    content: "";
    display: block;
    padding-top: 100%;
  }
  @media screen and (max-width: 1030px) {
    /* Take up full space */
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 730px) {
    height: auto;
    :before {
      /* Smaller for mobile */
      content: "";
      display: block;
      padding-top: 70%;
    }
  }
`
const Title = styled.h2`
  font-size: 1.6rem;
  @media screen and (min-width: 1030px) {
    /* Only do before modal starts shrinking and we don't have much description text */
    ${({ shortDescription }) => {
      if (shortDescription) return "font-size: 2rem;"
    }}
  }
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 0rem;
  @media screen and (max-width: 730px) {
    font-size: 1.3rem;
  }
`
const TenantName = styled.h3`
  letter-spacing: 0.03em;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`
const Content = styled.div`
  padding-top: 0.75rem;
  padding-left: 32px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
  /* Includes description text */
  p {
    line-height: 1.4;
    font-size: 14px;
  }
  @media screen and (max-width: 730px) {
    padding-left: 0px;
    padding-right: 0px;
    p {
      font-size: 13px;
    }
  }
`
const Main = styled.div`
  display: flex;
  @media screen and (max-width: 730px) {
    flex-direction: column;
  }
  .desktopOnly {
    @media screen and (max-width: 730px) {
      display: none !important;
    }
  }
  .mobileOnly {
    @media screen and (min-width: 731px) {
      display: none !important;
    }
  }
`
const PriceContainer = styled.div`
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
  @media screen and (max-width: 730px) {
    font-size: 0.8rem;
    margin-bottom: 0.15rem;
  }
`

const ProductPage = ({
  item = {},
  item: {
    id: productId,
    price,
    name,
    description,
    quantityAvailable,
    requiresShipping,
    storePickupEnabled,
    imageUrl,
  } = {},
  onAddToCart,
  onClose,
}) => {
  const [, , , addToCart, , { tenantName, tenantId, tenantPaymentAccounts } = {}] = useCartPrePayment()
  const [qty, setQty] = useState(1)
  const [customerNotes, setCustomerNotes] = useState("")
  const addProductToCart = () => {
    onAddToCart()
    addToCart([
      {
        product: item,
        quantity: qty,
        customerNotes,
      },
    ])
  }

  const hasChargesEnabledPaymentAccount =
        (tenantPaymentAccounts || []).findIndex(({ isDefault, chargesEnabled }) => isDefault === true && chargesEnabled === true) > -1
  
  // If tenant hasn't told us a qty, don't need to show in-stock might even be a digital product
  const showInStockMsg = !(
    (quantityAvailable === null || quantityAvailable === undefined)
  )
  const maxQty = useProductMaxQty({
    quantityAvailable,
    productId,
    tenantId,
  })
  const soldOut = maxQty <= 0 || !hasChargesEnabledPaymentAccount
  return (
    <Main>
      <Container>
        <ImageContainer>
          <ProductImage
            style={{
              border: "none", // want to remove and define in ImageContainer for ALL products not just missing images
              borderRadius, // Pass borderRadius for empty border
            }}
            productId={productId}
            alt={name}
          />
          <ProductShare item={item} tenantName={tenantName} />
        </ImageContainer>
      </Container>
      <Content>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <TenantName className={"desktopOnly"}>{tenantName}</TenantName>
          <Title shortDescription={(description || "").length < 300}>
            {name}
          </Title>
          <PriceContainer>
            <Price style={{ fontSize: "1.6em" }} price={price} />
          </PriceContainer>
          <ProductPageDescriptionText description={description} />
        </div>
        <div
          style={{ marginBottom: "12px", display: "flex", flexWrap: "wrap" }}
        >
          {showInStockMsg && (
            <div style={{ marginRight: 20 }}>
              <p style={{ marginTop: ".5rem", marginBottom: 0 }}>
                <ProductStockText maxQty={maxQty} />
              </p>
            </div>
          )}
          {requiresShipping && (
            <div style={{ marginRight: 20 }}>
              <p style={{ marginTop: ".5rem", marginBottom: 0 }}>
                <ProductShippingText />
              </p>
            </div>
          )}
          {storePickupEnabled && (
            <div style={{ marginRight: 20 }}>
              <p style={{ marginTop: ".5rem", marginBottom: 0 }}>
                <ProductPickupText />
              </p>
            </div>
          )}
        </div>
        {/* TODO: NOT ALL PRODUCTS SHOULD ALLOW QTY DROP DOWN... just allow 1 */}
        {soldOut ? (
          <ProductOutOfStock
            item={item}
            productId={productId}
            tenantId={tenantId}
            onClose={onClose}
          />
        ) : (
          <>
            <div
              style={{
                marginBottom: "12px",
              }}
            >
              <ProductQuantitySelect
                style={{ height: 45 }}
                onChange={v => setQty(v)}
                qty={qty}
                maxQty={maxQty}
              />
              <ProductCustomerNotes
                onChange={n => setCustomerNotes(n)}
                notes={customerNotes}
              />
            </div>
            <ButtonPrimary
              style={{ height: 45 }}
              onClick={() => {
                segmentAction.track(
                  `Sparkles Product Add To Cart`,
                  { quantity: qty },
                  { product: item }
                )
                addProductToCart()
              }}
            >
              Add to Cart
            </ButtonPrimary>
          </>
        )}
      </Content>
    </Main>
  )
}

export default ProductPage
