import React from "react"
import styled, { keyframes } from "styled-components"
import IconClose from "../icons/IconClose"
import { ButtonGray } from "./Buttons"
import chroma from "chroma-js"

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const comeUp = keyframes`
  0% {
    margin-top: 5px;
  }
  100% {
    margin-top: 0px;
  }
`

// For prompts with h2, h3, headers... good defaults
export const Content = styled.div`
  h3 {
    margin-bottom: 10px;
    font-size: 1.02rem;
    font-weight: bold;
    line-height: 1.2;
  }
  h2 {
    margin-bottom: 1rem;
    font-size: 1.45rem;
    font-weight: bold;
    line-height: 1.2;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.45;
    margin-bottom: 0.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    font-weight: bold;
    color: ${({ theme }) => chroma(theme.colors.accent).luminance(0.2)};
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  strong {
    color: #444;
  }
`

export const Bg = styled.div`
  display: flex;
  padding: 0.5rem;
  margin: auto;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  transform: translateZ(0);
  background: rgba(25, 25, 25, 0.6);
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  position: fixed;
  overflow: auto;
  animation: 0.1s ${fadeIn};
  z-index: 100000; /* Just needs to be higher than our "back to top btn" */
  &.hide {
    visibility: hidden !important;
    pointer-events: none !important;
    z-index: -10000 !important;
    position: absolute;
    * {
      visibility: hidden !important;
      pointer-events: none !important;
      opacity: 0 !important;
    }
  }
`

// Without any media queries / width
export const Panel = styled.div`
  :focus {
    outline: none;
  }
  :focus-visible {
    box-shadow: 0 0 0 2px black;
  }
  position: relative;
  border-radius: 20px;
  background: #fff;
  border: 1px solid #ccc;
  height: auto;
  margin: auto;
  overflow-x: hidden;
  text-align: center;
  animation: 0.1s ${comeUp};
`

export const Close = (props) => (
  <ButtonGray
    {...props}
    style={{
      background: "#fff",
      position: "absolute",
      zIndex: 20, // want on top
      height: 28,
      width: 28,
      top: 8,
      right: 6,
      padding: 0,
      display: "flex",
    }}
  >
    <IconClose
      length={2}
      style={{
        width: "43%",
        height: "43%",
        margin: "auto",
      }}
    />
  </ButtonGray>
)

export const Back = ({ text, ...props }) => (
  <ButtonGray
    {...props}
    style={{
      background: "#fff",
      position: "absolute",
      zIndex: 20, // want on top
      borderRadius: "100%",
      height: 26,
      width: 26,
      top: 8,
      left: 8,
      padding: 0,
      display: "flex",
    }}
  >
    <svg
      viewBox={"0 0 1.25 1.75"}
      className={"pos-center"}
      style={{ width: "auto", height: "45%", overflow: "visible" }}
    >
      <path
        strokeLinecap={"round"}
        vectorEffect="non-scaling-stroke"
        d={"M1,0 0,0.875 1,1.75"}
        strokeWidth={2.5}
        fill={"transparent"}
        stroke={"#888"}
      />
    </svg>
    {text && (
      <div
        style={{
          position: "absolute",
          left: 26,
          letterSpacing: ".02em",
          fontWeight: "bold",
          color: "#888",
          top: 0,
          lineHeight: "27px",
          fontSize: "13px",
        }}
      >
        {text}
      </div>
    )}
  </ButtonGray>
)

export const IconContainer = styled.div`
  display: inline-flex;
  width: 65px;
  height: 65px;
  position: relative;
  color: #333;
`

export const IconCircle = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid #ccc;
  position: absolute;
  border-radius: 100%;
`
