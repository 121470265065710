/**
 *
 * Bookings Utils for Skip Payment Values & Coupon Values
 *
 * */

function transformToKeyValueObj(array, keyProp = "key", valueProp = "value") {
  const obj = {}
  for (const item of array) {
    // add key/value pair to obj
    obj[item[keyProp]] = item[valueProp]
  }

  return obj
}

function getSkipPaymentCodes(bookingId, formFields) {
  const skipPaymentFields = formFields.filter(
    (f) => f.paymentDisabledValues && f.paymentDisabledValues.length > 0
  )[0]

  // console.table(skipPaymentFields)

  if (!skipPaymentFields) return []

  const paymentDisabledKeys = skipPaymentFields.paymentDisabledValues
  const paymentDisabledQuantities = skipPaymentFields.paymentDisabledQuantities

  // console.log("paymentDisabledQuantities", paymentDisabledQuantities)
  const seatQuantitiesObj = paymentDisabledQuantities
    ? transformToKeyValueObj(paymentDisabledQuantities, "key", "quantity")
    : {}

  // console.log({ seatQuantitiesObj })

  const paymentDisabledValues = paymentDisabledKeys.map((k) => {
    return {
      bookingId,
      destinationPropertyName: skipPaymentFields.destinationPropertyName,
      code: k.toUpperCase(),
      quantity: seatQuantitiesObj[k] || 1,
      type: "BYPASS_PAYMENT",
      value: 100,
    }
  })

  return paymentDisabledValues
}

function getAvailableCoupons(bookingId, formFields) {
  const couponFields = formFields.filter(
    (f) => f.coupons && f.coupons.length > 0
  )

  if (couponFields.length === 0) return []

  const destinationPropertyName = couponFields[0].destinationPropertyName
  const couponsArray = couponFields[0].coupons

  // for each coupon in coupons array, add additionalFormId to object

  const coupons = couponsArray.map((c, i) => {
    const { code, type, value } = c

    return {
      bookingId,
      destinationPropertyName,
      code: code.toUpperCase(),
      quantity: 1,
      type,
      value,
    }
  })

  return coupons
}

function getAllAvailablePromoCodes(bookingId, additionalForm) {
  const formFields = additionalForm.formFields || []

  const skipPaymentCodes = getSkipPaymentCodes(bookingId, formFields) || []
  const availableCoupons = getAvailableCoupons(bookingId, formFields) || []

  return [...skipPaymentCodes, ...availableCoupons]
}

function getFormFieldsWithoutPromoCodes(bookingId, additionalForm) {
  const formFields = additionalForm.formFields || []

  // if formFields is empty, return empty array
  if (formFields.length === 0) return []

  // filter out skip payment fields &
  // filter out coupon fields
  const formFieldsWithoutPromoCodes = formFields.filter((f) => {
    const hasSkipPaymentCodes =
      f.paymentDisabledValues && f.paymentDisabledValues.length > 0
    const hasCoupons = f.coupons && f.coupons.length > 0
    return !hasSkipPaymentCodes && !hasCoupons
  })

  return formFieldsWithoutPromoCodes
}

export { getAllAvailablePromoCodes, getFormFieldsWithoutPromoCodes }
