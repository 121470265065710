import React, { useState, useEffect } from "react"
import moment from "moment"
import { segmentAction } from "../../hooks/useSegmentTrack"
import styled from "styled-components"
import PageErrorForm from "../PageErrorForm"
import BookingsModalSummary from "./BookingsModalSummary"
import BookingsModalCheckoutSummary from "./BookingsModalCheckoutSummary"
import BookingsModalContact from "./BookingsModalContact"
import Modals from "../../core/Modals"
import BookingsModalCalendar from "./BookingsModalCalendar"
import BookingsModalNylas from "./BookingsModalNylas"
import Content from "./BookingsModalContent"
import BookingsSuccess, {
  getBookingSuccessProps,
} from "../../sparkleSuccess/BookingsSuccess"
import useBookingRequiredPaymentToStripe from "../../hooks/useBookingRequiredPaymentToStripe"
import queryString from "query-string"
import { onContactSubmit } from "../utils"
import useWidgetModeContext from "../../hooks/useWidgetModeContext"
import BookingsModalDescription from "./BookingsModalDescription"
import CarouselEqualHeight from "../../core/CarouselEqualHeight"
import {
  timekitHeightPadding,
  summaryHeight,
} from "../../hooks/useTimekitWidgetSet"
import BookingsModalGroupClassSelections from "./BookingsModalGroupClassSelections"
import BookingsModalManualCalendar from "./BookingsModalManualCalendar"
import BookingsModalContactRequest from "./BookingsModalContactRequest"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`
const Summary = styled.section`
  padding-inline: 1rem;
  padding-block: 2rem 1rem;
  width: 100%;
  border-bottom: 1px solid #ccc;
  @media screen and (max-width: 800px) {
    padding-inline: 0.75em;
    padding-block: 2em 1rem;
  }
`
const Main = styled.div`
  position: relative;
  padding-inline: 1em;
  padding-block: 1em;
  text-align: left;
  flex: 1;
  background: #fff;
  @media screen and (max-width: 800px) {
    padding: 0.5rem;
  }
`

const getOnBack = ({
  view,
  tenantInfo,
  selectedId,
  setView,
  hasBookingDescription,
  iframeIndex,
  setIframeIndex,
  isGroupClass = false,
}) => {
  switch (view) {
    case "calendar": {
      if (hasBookingDescription) {
        return () => {
          segmentAction.track(
            `Sparkles Booking Back To Description`,
            { bookingId: selectedId },
            { tenantInfo }
          )
          setView("description")
        }
      } else return
    }
    case "contact": {
      return () => {
        segmentAction.track(
          `Sparkles Booking Back To Calendar`,
          { bookingId: selectedId },
          { tenantInfo }
        )
        setIframeIndex(iframeIndex + 1)

        if (isGroupClass) {
          setView("group-class-list")
        } else {
          setView("calendar")
        }
      }
    }
    default:
      return
  }
}

const ignorePaymentInit = false
const voluntaryContributionInit = (selectedBooking) => {
  let price = (selectedBooking || {}).price
  if ((selectedBooking || {}).payment_type === "optional")
    price = Math.ceil(price)
  return price
}

const BookingsModal = ({
  tenantInfo,
  tenantInfo: { tenantId, tenantTimezone, allowManualBookingRequests },
  location: { search },
  selectedBooking,
  clearBooking,
  timeZone,
  setTimeZone,
  setView,
  view: passedView, // 'first','description', 'calendar', 'contact', 'success', 'error',
  bookingInfo,
  setBookingInfo,
  setRefreshMaxedOutDays,
  timekitHeight,
}) => {
  const shouldUseNylas = tenantInfo && tenantInfo.tenantNylasAccountId

  const widgetMode = useWidgetModeContext()
  const {
    name,
    email,
    phone,
    description: notes,
    cancelBookingId,
    project,
    resource,
    directNylasEmbed,
  } = queryString.parse(search) // this is just on first load right???
  const bookingCompleted = () => setRefreshMaxedOutDays(true) // Redone at the end of each booking
  const [isProcessing, setIsProcessing] = useState(false)
  const [pendingPaymentId, setPendingPaymentId] = useState(null) // Held till payment finishes
  const [customerInfo, setCustomer] = useState({
    // TODO: Possibly move to PageBookings?
    // TODO: Put into local storage for returning to a booking from stripe?
    name,
    email,
    phone,
    description: notes ? decodeURIComponent(notes) : "",
    zoom: false,
    project: !cancelBookingId ? project : undefined, // Temp till removed from cancelBookings url
    resource,
    participants: [],
  })
  const [groupClassInstanceInfo, setGroupClassInstanceInfo] = useState({})

  // Optional payment
  const [voluntaryContribution, setVoluntaryContribution] = useState(
    voluntaryContributionInit(selectedBooking)
  )
  const [customTipValue, setCustomTipValue] = useState(5)
  const [includeTip, setIncludeTip] = useState(true)
  const allowPlatformFeeContributions = (selectedBooking || {})
    .allowPlatformFeeContributions

  const [coupons, setCoupons] = useState([])
  const [couponPropertyKey, setCouponPropertyKey] = useState()

  const [ignorePayment, setIgnorePayment] = useState(ignorePaymentInit) // used in optional payments only
  const [isContactOnly, setIsContactOnly] = useState(false) // used to skip booking flow and just collect a booking request with contact info

  // Nylas modal
  const [iframeIndex, setIframeIndex] = useState(0)

  // Reset customerInfo props when switching bookings
  // Reset pricing as well
  const selectedId = (selectedBooking || {}).id
  const selectedPrice = (selectedBooking || {}).price
  const selectedPaymentType = (selectedBooking || {}).payment_type

  useEffect(() => {
    if (selectedId) {
      setCustomer((prev) => {
        return {
          ...prev,
          // Resetting
          zoom: false,
          description: "",
          metadata: {},
        }
      })
      setIgnorePayment(ignorePaymentInit)
      setVoluntaryContribution(
        voluntaryContributionInit({
          price: selectedPrice,
          payment_type: selectedPaymentType,
        })
      ) // default to selected booking

      // reset coupons and couponPropertyKey
      setCoupons()
      setCouponPropertyKey()

      if (!allowPlatformFeeContributions) {
        setIncludeTip(false)
      }
    }
  }, [
    selectedId,
    selectedPrice,
    selectedPaymentType,
    allowPlatformFeeContributions,
  ])

  // Auto redirects to stripe for required payments
  // - Once `pendingPaymentId` exists
  useBookingRequiredPaymentToStripe({
    customerInfo,
    pendingPaymentId,
    tenantInfo,
    bookingInfo,
    selectedBooking,
    setIsProcessing,
    voluntaryContribution:
      selectedPaymentType === "optional" && !ignorePayment
        ? voluntaryContribution
        : undefined,
    customTipValue,
    includeTip,
  })

  // Set first view
  // Decide to show description or calendar first
  let view = passedView
  const hasBookingDescription = !!(
    (selectedBooking || {}).description || ""
  ).trim()
  const isGroupClass = !!((selectedBooking || {}).isGroupClass || false)
  if (passedView === "first") {
    if (hasBookingDescription) {
      view = "description"
    } else if (isGroupClass) {
      view = "group-class-list"
    } else {
      view = hasBookingDescription ? "description" : "calendar"
    }
  }

  // Keep modal a standard height... contact info might grow
  // Could use a diff method to ensure same height like: https://codesandbox.io/s/grow-to-the-largest-height-carousel-00sdf
  const contentStyle = {
    height: timekitHeight + timekitHeightPadding, // Prevent scrolling areas that don't need to be there especially on mobile,
  }

  const onEditTime = (useManualBooking) => {
    segmentAction.track(
      `Sparkles Booking Time Edit`,
      { bookingId: selectedId },
      { tenantInfo }
    )

    if (useManualBooking) {
      if (allowManualBookingRequests) {
        // allows manual booking flow as requested by bookee/customer
        setView("manual-booking")
      } else {
        // default - allow to submit a request with only contact information
        setIsContactOnly(true)
        setIgnorePayment(true)
        setView("manual-contact")
      }
    } else {
      setIframeIndex(iframeIndex + 1)

      if (isGroupClass) {
        setView("group-class-list")
      } else {
        setView("calendar")
      }
    }
  }
  const hide = !selectedBooking || !view
  const onBack = getOnBack({
    view,
    tenantInfo,
    selectedId,
    setView,
    hasBookingDescription,
    iframeIndex,
    setIframeIndex,
    isGroupClass,
  })

  return (
    // For some reason must also hide from this level as well as at the modal level
    // Gatsby was still showing modal even if it shouldn't...
    <div style={hide ? { opacity: 0, pointerEvents: "none" } : {}}>
      <Modals
        onBack={onBack}
        onlyCenterModals={widgetMode} // Do not want "mobile view" to tether to bottom of page
        type={"booking"}
        hide={hide}
        {...(view === "success" || view === "manual"
          ? getBookingSuccessProps(clearBooking, directNylasEmbed, selectedBooking)
          : {
              onClick: clearBooking,
              onClose: clearBooking,
            })}
      >
        {view === "success" || view === "manual" ? (
          <BookingsSuccess
            tenantInfo={tenantInfo}
            onClose={clearBooking}
            customerInfo={customerInfo}
            selectedBooking={selectedBooking}
            customTipValue={customTipValue}
            bookingInfo={{ ...(bookingInfo || {}), timeZone }}
            useManual={view === "manual"}
          />
        ) : (
          <Container>
            <Summary
              style={
                shouldUseNylas
                  ? { height: "auto" }
                  : {
                      minHeight: summaryHeight,
                    }
              }
            >
              <BookingsModalSummary
                timeZone={timeZone || ""}
                selectedBooking={selectedBooking}
                bookingInfo={bookingInfo}
                view={view}
                onEditTime={onEditTime}
                onBack={onBack}
                isGroupClass={isGroupClass}
              />
            </Summary>

            <Main
              key={`${(selectedBooking || {}).id || 0}`} // Prevents some bugginess with timekit by forcing a bookingsjs rerender... could be view by switching events and then the timezones... then would never fully update
            >
              {/* Carousel may not be needed anymore since we are setting an exact height now. Was used to display each item based on the max of all slides */}
              {/* NOTE: If you do remove carousel remember to always keep timekit rendering around especially the bookingjs id */}
              <CarouselEqualHeight
                items={[
                  // Description
                  {
                    name: "description",
                    elem: (
                      <Content
                        view={view}
                        contentStyle={contentStyle}
                        title={selectedBooking?.name || "Description"}
                      >
                        <BookingsModalDescription
                          setView={setView}
                          selectedBooking={selectedBooking}
                        />
                      </Content>
                    ),
                  },
                  // Error
                  {
                    name: "error",
                    elem: (
                      <Content
                        view={view}
                        title={""}
                        contentStyle={contentStyle}
                      >
                        <PageErrorForm
                          segmentLog={"while trying to book"}
                          sub={
                            "If this is an emergency, please call 651‑349‑4918."
                          }
                          btnText={"Exit"}
                          onClick={clearBooking}
                          style={{ paddingBottom: 30 }}
                        />
                      </Content>
                    ),
                  },
                  // Contact
                  {
                    name: "contact",
                    elem: (
                      <Content
                        view={view}
                        title={"Contact"}
                        contentStyle={contentStyle}
                      >
                        <BookingsModalContact
                          isContactOnly={isContactOnly}
                          isGroupClass={isGroupClass}
                          tenantInfo={tenantInfo}
                          ignorePayment={ignorePayment}
                          setIgnorePayment={setIgnorePayment}
                          setCustomer={setCustomer}
                          customerInfo={customerInfo}
                          booking={selectedBooking || {}}
                          coupons={coupons}
                          setCoupons={setCoupons}
                          couponPropertyKey={couponPropertyKey}
                          setCouponPropertyKey={setCouponPropertyKey}
                          voluntaryContribution={voluntaryContribution}
                          setVoluntaryContribution={setVoluntaryContribution}
                          customTipValue={customTipValue}
                          setCustomTipValue={setCustomTipValue}
                          includeTip={includeTip}
                          setIncludeTip={setIncludeTip}
                          onSubmit={() => {
                            selectedPaymentType !== "free" && !ignorePayment
                              ? setView("checkout-confirmation")
                              : onContactSubmit({
                                  bookingInfo,
                                  ignorePayment,
                                  customerInfo,
                                  selectedBooking,
                                  timeZone,
                                  tenantId,
                                  bookingCompleted, // TODO: remove? if key is reset will get double check times then
                                  setView,
                                  setIsProcessing,
                                  setPendingPaymentId,
                                })
                            // setCustomer({ ...customerInfo, metadata: {} })
                          }}
                          isProcessing={isProcessing}
                        />
                      </Content>
                    ),
                  },
                  // Paid Booking Checkout
                  {
                    name: "checkout-confirmation",
                    elem: (
                      <Content
                        view={view}
                        title={"Checkout"}
                        noHeadingPadding
                        contentStyle={contentStyle}
                      >
                        <BookingsModalCheckoutSummary
                          isContactOnly={isContactOnly}
                          isGroupClass={isGroupClass}
                          tenantInfo={tenantInfo}
                          ignorePayment={ignorePayment}
                          setIgnorePayment={setIgnorePayment}
                          setCustomer={setCustomer}
                          customerInfo={customerInfo}
                          booking={selectedBooking || {}}
                          groupClassInstanceInfo={groupClassInstanceInfo}
                          coupons={coupons}
                          couponPropertyKey={couponPropertyKey}
                          setCouponPropertyKey={setCouponPropertyKey}
                          voluntaryContribution={voluntaryContribution}
                          setVoluntaryContribution={setVoluntaryContribution}
                          customTipValue={customTipValue}
                          setCustomTipValue={setCustomTipValue}
                          includeTip={includeTip}
                          setIncludeTip={setIncludeTip}
                          onSubmit={() => {
                            onContactSubmit({
                              bookingInfo,
                              ignorePayment,
                              customerInfo,
                              selectedBooking,
                              timeZone,
                              tenantId,
                              bookingCompleted, // TODO: remove? if key is reset will get double check times then
                              setView,
                              setIsProcessing,
                              setPendingPaymentId,
                            })
                            // setCustomer({ ...customerInfo, metadata: {} })
                          }}
                          isProcessing={isProcessing}
                        />
                      </Content>
                    ),
                  },
                  // Group Class List
                  {
                    name: "group-class-list",
                    elem: (
                      <Content
                        view={view}
                        // title={"Select Time"}
                        noHeadingPadding
                        contentStyle={contentStyle}
                        style={{
                          height: "100%",
                          overflowY: "scroll",
                          WebkitMaskImage:
                            "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 100%)",
                          maskImage:
                            "linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,1) 100%)",
                        }}
                      >
                        <BookingsModalGroupClassSelections
                          tenantId={tenantId}
                          tenantInfo={tenantInfo}
                          selectedBooking={selectedBooking}
                          timekitHeight={timekitHeight}
                          setView={setView}
                          setBookingInfo={setBookingInfo}
                          setGroupClassInstanceInfo={setGroupClassInstanceInfo}
                          setTimeZone={setTimeZone}
                          timezone={tenantTimezone}
                          onEditTime={onEditTime}
                        />
                      </Content>
                    ),
                  },
                  // Calendar
                  {
                    name: "calendar",
                    elem: (
                      <Content
                        view={view}
                        // title={"Select Time"}
                        noHeadingPadding
                        contentStyle={
                          shouldUseNylas
                            ? {
                                ...contentStyle,
                                height: contentStyle.height + 25,
                              }
                            : contentStyle
                        }
                      >
                        {shouldUseNylas ? (
                          <BookingsModalNylas
                            selectedBooking={selectedBooking}
                            timekitHeight={timekitHeight}
                            setView={setView}
                            setBookingInfo={setBookingInfo}
                            setTimeZone={setTimeZone}
                            timezone={tenantTimezone}
                            iframeIndex={iframeIndex}
                            onEditTime={onEditTime}
                          />
                        ) : (
                          <BookingsModalCalendar />
                        )}
                      </Content>
                    ),
                  },
                  // Manual Contact
                  {
                    name: "manual-contact",
                    elem: (
                      <Content
                        view={view}
                        title={"Thanks for your interest!"}
                        contentStyle={contentStyle}
                      >
                        <BookingsModalContactRequest
                          isContactOnly={isContactOnly}
                          isGroupClass={isGroupClass}
                          tenantInfo={tenantInfo}
                          setCustomer={setCustomer}
                          customerInfo={customerInfo}
                          booking={selectedBooking || {}}
                          onSubmit={() => {
                            onContactSubmit({
                              customerInfo,
                              selectedBooking,
                              bookingInfo: {
                                resources: [{ id: null, timezone: null }],
                                start: moment(),
                                end: moment(),
                                timezone: null,
                                booking: {
                                  graph: "manual-contact",
                                  id:
                                    selectedBooking && selectedBooking.projectId
                                      ? selectedBooking.projectId
                                      : null,
                                  resource: null,
                                },
                              },
                              tenantId,
                              setView,
                              setIsProcessing,
                            })
                          }}
                          isProcessing={isProcessing}
                        />
                      </Content>
                    ),
                  },
                  // Manual Booking
                  {
                    name: "manual-booking",
                    elem: (
                      <Content
                        view={view}
                        // title={"Select Time"}
                        noHeadingPadding
                        contentStyle={contentStyle}
                      >
                        <BookingsModalManualCalendar
                          tenantId={tenantId}
                          tenantInfo={tenantInfo}
                          selectedBooking={selectedBooking}
                          timekitHeight={timekitHeight}
                          setView={setView}
                          setBookingInfo={setBookingInfo}
                          setTimeZone={setTimeZone}
                          timezone={tenantTimezone}
                          setIgnorePayment={setIgnorePayment}
                          setIsContactOnly={setIsContactOnly}
                        />
                      </Content>
                    ),
                  },
                ]}
                active={view}
              />
            </Main>
          </Container>
        )}
      </Modals>
    </div>
  )
}

export default BookingsModal
