import React, { useState, useEffect } from "react"
import axios from "axios"
import { ButtonGrayOutline } from "../../core/Buttons"
import ReactTagInput from "@pathofdev/react-tag-input"
import {
  SubmitText,
  CheckBoxLabel,
  Label,
  ReactTagInputContainer,
  Submit,
  OptTipInput,
} from "./BookingsModalContactFormElems"
import isEmail from "validator/lib/isEmail"
import { segmentAction } from "../../hooks/useSegmentTrack"
import {
  getAllAvailablePromoCodes,
  getFormFieldsWithoutPromoCodes,
} from "./utils"

const BookingsModalContact = ({
  customerInfo,
  setCustomer,
  coupons,
  setCoupons,
  couponPropertyKey,
  setCouponPropertyKey,
  setVoluntaryContribution,
  voluntaryContribution,
  booking: {
    price: initPrice,
    additionalFormId,
    payment_type,
    descriptionLabel,
    id,
  },
  onSubmit,
  setIgnorePayment,
  isProcessing,
  ignorePayment,
  tenantInfo,
  isGroupClass,
  isContactOnly,
}) => {
  const [clickedSubmit, setClickedSubmit] = useState(false)
  const [showParticipants, setShowParticipants] = useState(false)
  const [additionalForm, setAdditionalForm] = useState()
  const [customFormFields, setCustomFormFields] = useState([])

  const onCustomerKeyChange =
    (key, checked = false) =>
    (e) => {
      const value = checked ? e.target.checked : e.target.value // good to capture before set state stuff (async)
      setCustomer((prev) => {
        return { ...prev, [key]: value }
      })
    }

  const onCustomerMetaKeyChange =
    (key, checked = false) =>
    (e) => {
      const value = checked ? e.target.checked : e.target.value // good to capture before set state stuff (async)

      setCustomer((prev) => {
        return { ...prev, metadata: { ...prev.metadata, [key]: value } }
      })
    }

  const onCustomerMetaKeyDirectChange = (e, key, checked = false) => {
    const value = checked ? e.target.checked : e.target.value // good to capture before set state stuff (async)
    setCustomer((prev) => {
      return { ...prev, metadata: { ...prev.metadata, [key]: value } }
    })
  }

  const updateParticipants = (nextParticipants) => {
    setCustomer((prev) => {
      return { ...prev, participants: nextParticipants }
    })
  }

  const validEmail = isEmail((customerInfo || {}).email || "")
  const showEmailError = clickedSubmit && !validEmail
  const hasErrors = !validEmail

  useEffect(() => {
    if (additionalFormId) {
      // call for list of classes
      const baseURL = process.env.GATSBY_SPARKLE_API_PATH
      const apiCode = process.env.GATSBY_SPARKLE_API_CODE
      const api = axios.create({ baseURL })
      api
        .get(
          `GeneralFormsHandler?code=${apiCode}&tenantId=${tenantInfo.tenantId}&tenantFormId=${additionalFormId}`
        )
        .then((response) => {
          if (response.data) {
            setAdditionalForm(response.data)
          }
        })
        .catch((error) => console.log({ error }))
    }
  }, [additionalFormId])

  useEffect(() => {
    if (additionalForm && id && setCoupons) {
      const availableCoupons = getAllAvailablePromoCodes(id, additionalForm)
      // console.table(availableCoupons)
      setCoupons(availableCoupons)
    }
    if (additionalForm && id) {
      const remainingFields = getFormFieldsWithoutPromoCodes(id, additionalForm)

      setCustomFormFields(remainingFields)
    }
  }, [additionalForm, id, setCoupons])

  // For voluntary payment
  const min = 1
  const max = 99999
  const step = 1
  return (
    <>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flex: 1,
          justifyContent: "space-between",
        }}
        onSubmit={(e) => {
          e.preventDefault()
          setClickedSubmit(true)
          // TODO: This should be passed in instead of individual info passed to onSubmit
          if (onSubmit && !hasErrors) {
            segmentAction.track(
              `Sparkles Booking Submit`,
              { bookingId: id },
              { tenantInfo }
            )
            onSubmit(e)
          } else {
            segmentAction.track(
              `Sparkles Booking Submit Error`,
              { bookingId: id, value: "Invalid Email" }, // for now only invalid opt is email that can somehow pass browser form validation ie email@e
              { tenantInfo }
            )
          }
        }}
      >
        <div style={{ overflowY: "scroll" }}>
          <Label>
            <span>Name *</span>
            <input
              type="text"
              name="name"
              required
              defaultValue={customerInfo.name}
              onChange={onCustomerKeyChange("name")}
            />
          </Label>
          <Label>
            <span>Phone</span>
            <input
              type="text"
              name="phone"
              defaultValue={customerInfo.phone}
              onChange={onCustomerKeyChange("phone")}
            />
          </Label>
          {customerInfo.phone && (
            <Label>
              <span>Opt-in SMS/Text Reminders</span>
              <select
                id="smstextreminders"
                defaultValue={null}
                onChange={onCustomerMetaKeyChange("enableSmsReminders")}
                required={false}
              >
                <option key={-999} value={null}>
                  -Select-
                </option>
                <option key={"yes"} value={true}>
                  Yes
                </option>
                <option key={"no"} value={false}>
                  No
                </option>
              </select>
            </Label>
          )}
          <Label style={{ position: "relative" }}>
            <span>
              E-mail *{" "}
              {showEmailError ? (
                <i style={{ color: "var(--error)" }}> Invalid email</i>
              ) : (
                ""
              )}
            </span>
            <div style={{ display: "flex" }}>
              <input
                style={{ paddingRight: "9ch" }}
                className={showEmailError ? "error" : ""}
                type="email"
                name="email"
                required
                defaultValue={customerInfo.email}
                onChange={onCustomerKeyChange("email")}
              />
              {!isGroupClass && (
                <ButtonGrayOutline
                  type="button"
                  style={{
                    paddingLeft: "1.5em",
                    paddingRight: "1.5em",
                    height: "100%",
                    whiteSpace: "nowrap",
                    display: "flex",
                    marginLeft: 10,
                    fontSize: ".75rem",
                    lineHeight: 1.3,
                    alignItems: "center",
                    borderRadius: "15px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowParticipants((v) => !v)
                    if (showParticipants)
                      // Clearing out
                      setCustomer((prev) => {
                        return { ...prev, participants: [] }
                      })
                  }}
                >
                  {showParticipants ? (
                    <>
                      Remove
                      <br />
                      Guests
                    </>
                  ) : (
                    <>
                      Add
                      <br />
                      Guests
                    </>
                  )}
                </ButtonGrayOutline>
              )}
            </div>
          </Label>{" "}
          <ReactTagInputContainer
            as={"div"}
            style={{ display: showParticipants ? "" : "none" }}
          >
            <span>{`Guest emails${
              (customerInfo.participants || []).length > 0
                ? ` - ${(customerInfo.participants || []).length} added`
                : ""
            }`}</span>
            <ReactTagInput
              separatorKeys={[32, 9, 188]}
              tags={customerInfo.participants || []}
              placeholder="Type email and press enter to add"
              maxTags={20}
              readOnly={false}
              removeOnBackspace={true}
              onChange={updateParticipants}
              validator={(value) => {
                // Will not add invalid emails to array - only way to tell user however is to alert... maybe could do something fancier someday
                const isValid =
                  isEmail(value || "") && value !== customerInfo.email
                if (!isValid)
                  alert(
                    value === customerInfo.email
                      ? "Make sure email is different than your own"
                      : "Email not valid."
                  )
                return isValid
              }}
            />
          </ReactTagInputContainer>
          <Label>
            <span>{descriptionLabel || "Notes for booking"}</span>
            <textarea
              lines={2}
              name="description"
              placeholder="optional"
              defaultValue={customerInfo.description}
              onChange={onCustomerKeyChange("description")}
            />
          </Label>
          {additionalForm && coupons && coupons.length > 0 && (
            <Label>
              <span>Promo Code</span>
              <input
                type="text"
                name="promo"
                defaultValue=""
                onChange={onCustomerMetaKeyChange("_INTERNAL_PROMO_CODE")}
                style={{ textTransform: "uppercase" }}
              />
            </Label>
          )}
          {additionalForm &&
            customFormFields
              .sort((a, b) => a.order - b.order)
              .map((o, i) => {
                if (o.type === "select") {
                  return (
                    <Label key={`${o.destinationPropertyName}-i`}>
                      <span>{o.formFieldName}</span>
                      <select
                        id={o.destinationPropertyName}
                        defaultValue={null}
                        onChange={onCustomerMetaKeyChange(
                          o.destinationPropertyName
                        )}
                        required={o.isRequired || false}
                      >
                        <option key={-999} value={null}>
                          -Select-
                        </option>
                        {(o.optionValues || []).map((p) => (
                          <option key={p} value={p}>
                            {p}
                          </option>
                        ))}
                      </select>
                    </Label>
                  )
                }

                // Default - text
                return (
                  <Label key={`${o.destinationPropertyName}-i`}>
                    <span>{o.formFieldName}</span>
                    <input
                      type="text"
                      name={o.destinationPropertyName}
                      defaultValue=""
                      onChange={onCustomerMetaKeyChange(
                        o.destinationPropertyName
                      )}
                      required={o.isRequired || false}
                    />
                  </Label>
                )
              })}
          {payment_type === "optional" && initPrice && (
            <CheckBoxLabel>
              <input
                onChange={() => {
                  setIgnorePayment((prev) => !prev)
                }}
                checked={!ignorePayment}
                type="checkbox"
              />
              <span
                style={
                  ignorePayment
                    ? { opacity: 0.6, transition: ".2s" }
                    : { opacity: 1, transition: ".2s" }
                }
              >
                Yes, add tip of{" "}
                <span style={{ marginLeft: ".2em", whiteSpace: "nowrap" }}>
                  $
                  <OptTipInput
                    disabled={ignorePayment} // to bypass browser validation when not needed
                    required={!ignorePayment}
                    onChange={(e) => {
                      const num = e.target.value
                      setVoluntaryContribution(num)
                    }}
                    onPointerDown={(e) => {
                      setIgnorePayment(false)
                    }}
                    type={"number"}
                    min={min}
                    max={max}
                    value={voluntaryContribution}
                    step={step}
                  />
                </span>
              </span>
            </CheckBoxLabel>
          )}
        </div>
        <Submit
          className={isProcessing ? "loading" : ""}
          style={{
            marginTop: 10,
          }}
        >
          {/* Check for id just so we don't get error prior to selecting element */}
          {id && (
            <SubmitText
              payment_type={payment_type}
              isProcessing={isProcessing}
              ignorePayment={ignorePayment}
            />
          )}
        </Submit>
      </form>
    </>
  )
}

export default BookingsModalContact
