import React, { useState, useEffect } from "react"
import {
  SubmitText,
  CheckBoxLabel,
  Submit,
  OptTipInput,
} from "./BookingsModalContactFormElems"
import isEmail from "validator/lib/isEmail"
import { segmentAction } from "../../hooks/useSegmentTrack"
import IncrementalInput from "../../core/IncrementalInput"

const BookingsModalCheckoutSummary = ({
  customerInfo,
  setCustomer,
  setVoluntaryContribution,
  voluntaryContribution,
  setCustomTipValue,
  customTipValue,
  includeTip,
  setIncludeTip,
  coupons,
  couponPropertyKey,
  setCouponPropertyKey,
  booking: {
    price: initPrice,
    payment_type,
    descriptionLabel,
    name: eventName,
    allowPlatformFeeContributions,
    id,
  },
  groupClassInstanceInfo,
  onSubmit,
  setIgnorePayment,
  isProcessing,
  ignorePayment,
  tenantInfo,
  isGroupClass,
  isContactOnly,
  setView,
}) => {
  const [customTipText, setCustomTipText] = useState()
  const [checkoutEventLabel, setCheckoutEventLabel] = useState()

  const { max_seats } =
    (groupClassInstanceInfo && groupClassInstanceInfo.group_booking) || {}
  const [seatQuantity, setSeatQuantity] = useState(1)

  const [finalTotal, setFinalTotal] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [discountedSubtotal, setDiscountedSubtotal] = useState(0)
  const [discountedUnitPrice, setDiscountedUnitPrice] = useState(0)
  const [unitPrice, setUnitPrice] = useState(initPrice)

  const [appliedCoupon, setAppliedCoupon] = useState()

  const [clickedSubmit, setClickedSubmit] = useState(false)

  // if id changes, reset appliedCoupon, discount and total
  useEffect(() => {
    setAppliedCoupon()
    setSeatQuantity(1)
    setSubtotal(0)
    setDiscountedUnitPrice(0)
    setDiscountedSubtotal(0)
    setFinalTotal(0)
  }, [])

  useEffect(() => {
    if (payment_type === "optional") {
      setCheckoutEventLabel("Voluntary Contribution")
    } else {
      setCheckoutEventLabel(eventName)
    }
  }, [payment_type, eventName])

  // checks for coupon and sets discounted price
  /* _INTERNAL_PROMO_CODE - is hard-coded value to match coupons and skip payments values */
  useEffect(() => {
    if (
      coupons &&
      customerInfo &&
      customerInfo.metadata &&
      customerInfo.metadata["_INTERNAL_PROMO_CODE"] &&
      id
    ) {
      const customerCouponValue = customerInfo.metadata["_INTERNAL_PROMO_CODE"]

      const coupon = coupons.find(
        (coupon) =>
          coupon.code.toUpperCase() === customerCouponValue.toUpperCase()
      )

      if (coupon && id === coupon.bookingId) {
        setAppliedCoupon(coupon)
        setCouponPropertyKey(coupon.destinationPropertyName)
        setSeatQuantity(coupon.quantity)
        if (coupon.type === "BYPASS_PAYMENT") {
          setIgnorePayment(true)
        } else if (coupon.type === "BOGO") {
          setDiscountedUnitPrice(initPrice)
          setSeatQuantity(2)
        } else {
          setDiscountedUnitPrice(initPrice - initPrice * (coupon.value / 100))
        }
      }
    }

    if (
      customerInfo &&
      customerInfo.metadata &&
      id &&
      customerInfo.metadata["Sparkle_Seat_Quantity"]
    ) {
      const customerSeatQuantity =
        customerInfo.metadata["Sparkle_Seat_Quantity"]
      const couponQuantity = appliedCoupon ? appliedCoupon.quantity : 1

      if (appliedCoupon && appliedCoupon.type === "BYPASS_PAYMENT") {
        setSeatQuantity(couponQuantity)
      } else {
        setSeatQuantity(customerSeatQuantity)
      }
    }
  }, [appliedCoupon, customerInfo, coupons, discountedSubtotal, id])

  const updateParticipants = (nextParticipants) => {
    setCustomer((prev) => {
      return { ...prev, participants: nextParticipants }
    })
  }

  const validEmail = isEmail((customerInfo || {}).email || "")
  const showEmailError = clickedSubmit && !validEmail
  const hasErrors = !validEmail

  // does the math for total price
  useEffect(() => {
    let updatedFinalTotal = 0
    let updatedSubtotal = 0
    let updatedDiscountedSubtotal = 0
    let tipValue = includeTip ? Number(customTipValue) : 0

    switch (true) {
      case appliedCoupon && appliedCoupon.type === "Percent":
        updatedSubtotal = initPrice * seatQuantity
        updatedDiscountedSubtotal = Number(discountedUnitPrice * seatQuantity)
        updatedFinalTotal = discountedUnitPrice * seatQuantity + tipValue
        break
      case appliedCoupon && appliedCoupon.type === "Dollars":
        updatedSubtotal = initPrice * seatQuantity
        updatedDiscountedSubtotal = Number(
          unitPrice * seatQuantity - Number(appliedCoupon.value)
        )
        updatedFinalTotal = updatedDiscountedSubtotal + tipValue
        break
      case appliedCoupon && appliedCoupon.type === "BOGO":
        updatedSubtotal = initPrice * 2
        updatedDiscountedSubtotal = Number(updatedSubtotal * 0.5)
        updatedFinalTotal = updatedDiscountedSubtotal + tipValue
        break
      case payment_type === "optional":
        updatedSubtotal = Number(voluntaryContribution)
        updatedFinalTotal = Number(voluntaryContribution)
        break
      default:
        updatedSubtotal = initPrice * seatQuantity
        updatedFinalTotal = initPrice * seatQuantity + tipValue
        break
    }

    setSubtotal(updatedSubtotal.toFixed(2))
    setDiscountedSubtotal(updatedDiscountedSubtotal.toFixed(2))
    setFinalTotal(updatedFinalTotal.toFixed(2))
  }, [
    customerInfo,
    voluntaryContribution,
    customTipValue,
    includeTip,
    discountedSubtotal,
    subtotal,
    finalTotal,
    seatQuantity,
  ])

  // For voluntary payment
  const min = 1
  const max = 99999
  const step = 1

  useEffect(() => {
    if (appliedCoupon && couponPropertyKey) {
      console.log(seatQuantity, couponPropertyKey, customerInfo.metadata)
      setCustomer((prev) => {
        return {
          ...prev,
          metadata: {
            ...prev.metadata,
            [couponPropertyKey]: appliedCoupon.code,
            Sparkle_Seat_Quantity: seatQuantity,
          },
        }
      })
    }
  }, [appliedCoupon, couponPropertyKey, seatQuantity])

  function getSeatIncrementer(
    appliedCoupon,
    payment_type,
    isGroupClass,
    seatQuantity,
    max_seats,
    setCustomer
  ) {
    if (payment_type === "optional" || !isGroupClass) {
      return "1"
    } else if (appliedCoupon && appliedCoupon.type === "BOGO") {
      return "2"
    } else {
      return (
        <IncrementalInput
          initValue={seatQuantity}
          maxValue={max_seats}
          updateCount={(count) => {
            setCustomer((prev) => {
              return {
                ...prev,
                metadata: {
                  Sparkle_Seat_Quantity: count,
                },
              }
            })
          }}
        />
      )
    }
  }

  return (
    <>
      {customerInfo && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            paddingBottom: "1em",
          }}
        >
          <p>{customerInfo.name}</p>
          <p>{customerInfo.email}</p>
          <p>{customerInfo.phone}</p>
        </div>
      )}
      <hr />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flex: 1,
          justifyContent: "space-between",
        }}
        onSubmit={(e) => {
          e.preventDefault()
          setClickedSubmit(true)

          // TODO: This should be passed in instead of individual info passed to onSubmit
          if (onSubmit && !hasErrors) {
            segmentAction.track(
              `Sparkles Booking Submit`,
              { bookingId: id },
              { tenantInfo }
            )

            onSubmit(e)
          } else {
            segmentAction.track(
              `Sparkles Booking Submit Error`,
              { bookingId: id, value: "Invalid Email" }, // for now only invalid opt is email that can somehow pass browser form validation ie email@e
              { tenantInfo }
            )
          }
        }}
      >
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Qty
              </th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Item Price
              </th>
              <th
                style={{
                  textAlign: "right",
                }}
              >
                Total
              </th>
            </tr>
          </thead>

          {initPrice && (
            <tbody>
              <tr>
                <td>{checkoutEventLabel}</td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                >
                  {getSeatIncrementer(
                    appliedCoupon,
                    payment_type,
                    isGroupClass,
                    seatQuantity,
                    max_seats,
                    setCustomer
                  )}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    minWidth: "7ch",
                  }}
                >
                  $ {parseFloat(voluntaryContribution).toFixed(2)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    textDecoration: appliedCoupon ? "line-through" : "none",
                    minWidth: "7ch",
                  }}
                >
                  $ {subtotal}
                </td>
              </tr>
              {appliedCoupon && (
                <tr>
                  <td
                    style={{
                      paddingLeft: "1em",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <code>
                      <b>code: </b>
                      {appliedCoupon.code}
                    </code>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      color: "red",
                      fontSize: "0.85em",
                      fontWeight: "bold",
                    }}
                  >
                    {appliedCoupon.type === "Dollars"
                      ? `- $ ${parseFloat(appliedCoupon.value).toFixed(2)}`
                      : `- ${appliedCoupon.value}%`}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      color: "red",
                      fontSize: "0.85em",
                      fontWeight: "bold",
                    }}
                  >
                    $ {discountedSubtotal}
                  </td>
                </tr>
              )}
              {includeTip && (
                <tr>
                  <td
                    style={{
                      paddingTop: "0.5em",
                    }}
                  >
                    {customTipText || "Tip"}
                  </td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>
                    $ {parseFloat(customTipValue).toFixed(2)}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>

        {allowPlatformFeeContributions && (
          <>
            <p
              style={{
                marginBlock: "2em",
              }}
            ></p>
            <CheckBoxLabel
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <input
                onChange={() => {
                  setIncludeTip(!includeTip)
                }}
                checked={includeTip}
                type="checkbox"
              />

              <span
                style={
                  !includeTip
                    ? { opacity: 0.6, transition: ".2s" }
                    : { opacity: 1, transition: ".2s" }
                }
              >
                {customTipText
                  ? customTipText
                  : "Help us cover the cost of credit card processing fees, add an additional"}
              </span>
              <span
                style={{
                  marginLeft: ".25em",
                  whiteSpace: "nowrap",
                  paddingBottom: "0.1em",
                }}
              >
                $
                <OptTipInput
                  style={{
                    textAlign: "right",
                  }}
                  disabled={!includeTip} // to bypass browser validation when not needed
                  required={!includeTip}
                  onChange={(e) => {
                    const num = e.target.value
                    setCustomTipValue(num)
                  }}
                  type={"number"}
                  min={min}
                  max={max}
                  value={customTipValue}
                  step={step}
                />
              </span>
            </CheckBoxLabel>
          </>
        )}

        <hr />
        <h3
          style={{
            textAlign: "right",
            fontWeight: "bold",
          }}
        >
          Total: $ <span>{finalTotal}</span>
        </h3>
        <Submit
          className={isProcessing ? "loading" : ""}
          style={{ marginTop: 10 }}
        >
          {/* Check for id just so we don't get error prior to selecting element */}
          {id && (
            <SubmitText
              payment_type={payment_type}
              isProcessing={isProcessing}
              ignorePayment={ignorePayment}
            />
          )}
        </Submit>
      </form>
    </>
  )
}

export default BookingsModalCheckoutSummary
