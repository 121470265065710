import React, { useEffect, useState } from "react"

const IncrementalInput = ({
  initValue = 1,
  minValue = 1,
  maxValue,
  updateCount,
}) => {
  const [count, setCount] = useState(initValue)
  const [editMode, setEditMode] = useState(false)

  function incrementByOne(e) {
    e.preventDefault()
    if (maxValue && count >= maxValue) return
    setCount(count + 1)
  }

  function decrementByOne(e) {
    e.preventDefault()
    if (count <= minValue) return
    setCount(count - 1)
  }

  function handleCountChange(count) {
    if (!count || count < minValue) {
      return minValue
    } else if (count >= maxValue) {
      return maxValue
    } else {
      return count
    }
  }
  useEffect(() => {
    if (updateCount) {
      const newCount = handleCountChange(count)
      updateCount(newCount)
    }
  }, [count])

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button onClick={decrementByOne}>-</button>
      <input
        style={{
          padding: "0.5em",
          width: "6ch",
          textAlign: "center",
          fontSize: "1rem",
        }}
        type="numerical"
        inputMode="string"
        value={count}
        onClick={(e) => e.target.select()}
        onChange={(e) => {
          const updatedCount = handleCountChange(e.target.value)
          setCount(updatedCount)
        }}
      />

      <button onClick={incrementByOne}>+</button>
    </div>
  )
}

export default IncrementalInput
