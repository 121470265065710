import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getEmptyProductPatternId } from "../utils"
import useTenantInfoContext from "../../hooks/useTenantInfoContext"
import useIsBrowser from "../../hooks/useIsBrowser"

const ProductImage = ({ productId, alt, style = {} }) => {
  const [error, setError] = useState(false)
  const isBrowser = useIsBrowser()
  const tenantInfo = useTenantInfoContext()
  const {
    imageUrl: serverImageUrl,
    imageLastModified: serverImageLastModified,
  } = tenantInfo.tenantProducts.find(({ id }) => id === productId) || { imageUrl: '', imageLastModified: null  }

  /**
   * Note: Known limitations via gatsby docs (v4)
   *  - useStaticQuery does not accept variables (hence the name “static”),
   *    but can be used in any component, including pages
   *  - Because of how queries currently work in Gatsby, we support
   *    only a single instance of useStaticQuery in a file
   *
   *  NOTE: Using static query to grab all filepaths and filtering
   *        doesn't scale well, but seems to be what's within reach for gatsby.
   *        Will be a non-issue when we have a CDN!
   *    - Discussion for reference: https://github.com/gatsbyjs/gatsby/discussions/10482
   */

  const data = useStaticQuery(graphql`
    query {
      site {
        buildTime
      }
      allFile {
        nodes {
          id
          modifiedTime
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `)

  // timestamps in milliseconds (unix) fyi: null returns 0
  const serverImageDate = new Date(serverImageLastModified).getTime()
  const lastBuildTime = new Date(data.site.buildTime).getTime()

  const asset = data.allFile.nodes.find(product => product.id === productId)

  // Let's exit SSR stuff and do the rest live
  // - SSR cannot seem to handle `onError`
  if (!isBrowser) return null

  // If image error backup image (unlikely to happen unless something off with `imageUrl`)
  if (error) {
    console.log(`error img url:  ${serverImageUrl}?r=${serverImageDate}`)
    return (
      <div
        className={"pos-full"}
        style={{
          border: "1px solid rgba(0,0,0,.075)",
          background: "#f7f7f7",
          overflow: "hidden",
          ...style,
        }}
      >
        <svg className={"pos-full"}>
          <rect
            strokeWidth={0}
            fill={`url(#${getEmptyProductPatternId(tenantInfo)})`}
            width={"100%"}
            height={"100%"}
          />
        </svg>
      </div>
    )
  }

  // not using static images
  return (
    <img
      onError={() => setError(true)} // this is really just a backup... this should load
      alt={alt}
      style={{
        objectFit: "cover",
      }}
      className={"pos-full"}
      loading="lazy"
      src={`${serverImageUrl}?r=${serverImageDate}`}
    />
  )

  // // Latest + greatest image (from server)
  // if (serverImageDate > lastBuildTime) {
  //   // adding random query param to bust cache
  //   return (
  //     <img
  //       onError={() => setError(true)} // this is really just a backup... this should load
  //       alt={alt}
  //       style={{
  //         objectFit: "cover",
  //       }}
  //       className={"pos-full"}
  //       loading="lazy"
  //       src={`${serverImageUrl}?r=${serverImageDate}`}
  //     />
  //   )
  // } else {
  //   // Statically built / optimized image from gatsby
  //   const staticImage = getImage(asset)

  //   if (staticImage) {
  //     return (
  //       <GatsbyImage
  //         alt={alt}
  //         image={staticImage}
  //         className={"pos-full"}
  //         loading="lazy" // What does this mean?
  //       />
  //     )
  //   }
  // }

  // Backup image (unlikely to happen unless something off with `imageUrl`)
  return (
    <div
      className={"pos-full"}
      style={{
        border: "1px solid rgba(0,0,0,.075)",
        background: "#f7f7f7",
        overflow: "hidden",
        ...style,
      }}
    >
      <svg className={"pos-full"}>
        <rect
          strokeWidth={0}
          fill={`url(#${getEmptyProductPatternId(tenantInfo)})`}
          width={"100%"}
          height={"100%"}
        />
      </svg>
    </div>
  )
}

export default ProductImage

// [WIP] potential page query if we went with this approach
// const query = graphql`{

//       tenantProducts {
//         id
//         imageUrl
//         imageLastModified
//       }
//     }
//     allFile(filter: { id: { eq: $productId } }) {
//       nodes {
//         id
//         childImageSharp {
//           gatsbyImageData(
//             width: 1000
//             placeholder: BLURRED
//             formats: [AUTO, WEBP, AVIF]
//           )
//         }
//       }
//     }
//   }
// `
